import React from "react";
import { Dialog } from "primereact/dialog";
import LinkAdjuntos from "./LinkAdjuntos";
import {
  capitalizarPrimeraLetra,
  fechaHoraPopup,
  formatNumber,
} from "../helpers/index";
import { TIPOS_ADJUNTOS } from "../types/TiposAdjuntos";

const PopUpResumen = ({
  resumenVisible,
  rowDataSelection,
  setResumenVisible,
  tipoDeNovedad,
  setTipoDeNovedad,
}) => {
  const getLabelRol = (data) => {
    switch (data) {
      case "admin":
        return "Administración";

      case "servicios":
        return "Servicios";

      case "po":
        return "People Operation";

      case "ta":
        return "Talent Acquisition";

      case "cultura":
        return "Cultura";

      case "empleado":
        return "Empleado";

      case "comercial":
        return "Comercialización";

      default:
        return null;
    }
  };

  return (
    <>
      {resumenVisible && (
        <Dialog
          draggable={false}
          header={`Resumen completo de ${tipoDeNovedad}`}
          visible={resumenVisible}
          onHide={() => setResumenVisible(false)}
          style={{
            width: "50vw",
            wordWrap: "break-word",
            overflowWrap: "break-word",
          }}
        >
          <div className="m-0" style={{ width: "100%", overflowX: "hidden" }}>
            <b>Tipo de Novedad:</b> {rowDataSelection.Descripcion} <br />
            <b>ID:</b> {rowDataSelection.IdSecuencial} <br />
            <b>Usuario:</b> {rowDataSelection.Usuario} <br />
            <b>Fecha de Carga:</b> {rowDataSelection.Fecha}
            {" | "}
            {rowDataSelection.Hora} <br />
            <b>Estado:</b> {capitalizarPrimeraLetra(rowDataSelection.Estado)}{" "}
            <br />
            {/* WORKFLOW ACEPTADA O RECHAZADA */}
            {rowDataSelection.Workflow?.length <= 1 &&
              rowDataSelection.Estado !== "pendiente" &&
              rowDataSelection.Workflow?.map((e, i) =>
                e.Aprobador === "po" ||
                e.Aprobador === "ta" ||
                e.Aprobador === "cultura" ||
                e.Aprobador === "admin" ? (
                  rowDataSelection.Estado === "aceptada" ? (
                    <div key={i}>
                      <b>Aprobado por: </b>{" "}
                      {e.Autorizador +
                        " (" +
                        getLabelRol(e.Aprobador) +
                        ")" +
                        fechaHoraPopup(e.Fecha).fechaHora}
                    </div>
                  ) : (
                    <div key={i}>
                      <b>Rechazado por: </b>{" "}
                      {e.Autorizador +
                        " (" +
                        getLabelRol(e.Aprobador) +
                        ")" +
                        fechaHoraPopup(e.Fecha).fechaHora}
                    </div>
                  )
                ) : rowDataSelection.Estado === "aceptada" ? (
                  <div key={i}>
                    <b>Aprobado por: </b>{" "}
                    {e.Autorizador +
                      " (Gerente)" +
                      fechaHoraPopup(e.Fecha).fechaHora}
                  </div>
                ) : (
                  <div key={i}>
                    <b>Rechazado por: </b>{" "}
                    {e.Autorizador +
                      " (Gerente)" +
                      fechaHoraPopup(e.Fecha).fechaHora}
                  </div>
                )
              )}
            {/* WORKFLOW PENDIENTE (APROBADA POR EL GERENTE) */}
            {rowDataSelection.Workflow?.length > 1 &&
              rowDataSelection.Estado === "pendiente" &&
              rowDataSelection.Workflow?.map((e, i) => (
                <div key={i}>
                  {i === 0 && e.Estado === "aceptada" && (
                    <div>
                      <b>Aprobado por: </b>{" "}
                      {e.Autorizador +
                        " (Gerente)" +
                        fechaHoraPopup(e.Fecha).fechaHora}{" "}
                      <br />
                    </div>
                  )}
                  {i === 1 && (
                    <div>
                      <b>
                        <i>*Falta Aprobacion de {getLabelRol(e.Aprobador)}*</i>
                      </b>
                    </div>
                  )}
                </div>
              ))}
            {rowDataSelection.Workflow?.length > 1 &&
              rowDataSelection.Estado !== "pendiente" &&
              rowDataSelection.Workflow?.map((e, i) =>
                e.Aprobador === "po" ||
                e.Aprobador === "ta" ||
                e.Aprobador === "cultura" ||
                e.Aprobador === "admin" ? (
                  rowDataSelection.Estado === "aceptada" ? (
                    <div key={i}>
                      <b>Aprobado por: </b>{" "}
                      {e.Autorizador +
                        " (" +
                        getLabelRol(e.Aprobador) +
                        ")" +
                        fechaHoraPopup(e.Fecha).fechaHora}
                    </div>
                  ) : (
                    <div key={i}>
                      <b>Rechazado por: </b>{" "}
                      {e.Autorizador +
                        " (" +
                        getLabelRol(e.Aprobador) +
                        ")" +
                        fechaHoraPopup(e.Fecha).fechaHora}
                    </div>
                  )
                ) : e.Estado === "aceptada" ? (
                  <div key={i}>
                    <b>Aprobado por: </b>{" "}
                    {e.Autorizador +
                      " (Gerente)" +
                      fechaHoraPopup(e.Fecha).fechaHora}
                  </div>
                ) : (
                  <div key={i}>
                    <b>Rechazado por: </b>{" "}
                    {e.Autorizador +
                      " (Gerente)" +
                      fechaHoraPopup(e.Fecha).fechaHora}
                  </div>
                )
              )}
            {rowDataSelection.Estado === "aceptada" &&
              rowDataSelection.Motivo && (
                <>
                  <b>Motivo Aceptado: </b> {rowDataSelection.Motivo} <br />
                </>
              )}
            {rowDataSelection.Estado === "rechazada" &&
              rowDataSelection.Motivo && (
                <>
                  <b>Motivo Rechazado: </b> {rowDataSelection.Motivo} <br />
                </>
              )}
            {/* {(rowDataSelection.Estado === "aceptada") && (
              <>
                <b>Motivo Aceptado: </b> {rowDataSelection.Motivo} <br />
              </>
            )
            }
             {(rowDataSelection.Estado === "rechazada") && (
              <>
                <b>Motivo Rechazado: </b> {rowDataSelection.Motivo} <br />
              </>
            )
            } */}
            <br />
            {/* TIPO DE NOVEDAD RECURSO */}
            {tipoDeNovedad === "Recursos" && (
              <div>
                {rowDataSelection.Recursos?.length > 0 && (
                  <div>
                    <b>Recursos: </b>
                    {rowDataSelection.Recursos?.map((itemB, index) =>
                      rowDataSelection.Descripcion.includes(
                        "Actualizar sueldo básico"
                      ) ? (
                        <div key={index} style={{ margin: "3px 0 3px 6px" }}>
                          <b>{itemB.Recurso}:</b> <br />
                          <i>Período: </i> {itemB.Periodo} <b>| </b>
                          <i>Sueldo Basico Actual:</i> $
                          {formatNumber(itemB.SbActual)} <b>| </b>
                          <i>Nuevo Básico:</i> ${formatNumber(itemB.Importe)}
                        </div>
                      ) : (
                        <div key={index} style={{ display: "block" }}>
                          {itemB.Recurso}
                          {itemB.Periodo !== "" && (
                            <>
                              {" "}
                              <i>Periodo:</i> {itemB.Periodo}{" "}
                            </>
                          )}
                          {itemB.Importe !== 0 && (
                            <>
                              {" "}
                              <b> | </b> $ {formatNumber(itemB.Importe)}{" "}
                            </>
                          )}
                        </div>
                      )
                    )}
                    {rowDataSelection.Recursos?.map((itemB, index) =>
                      rowDataSelection.Descripcion.includes(
                        "Actualizar sueldo básico"
                      )
                    )}
                    {rowDataSelection.Periodo !== "" && (
                      <div>
                        <b>Periodo: </b> {rowDataSelection.Periodo}
                      </div>
                    )}
                  </div>
                )}
                {rowDataSelection.Distribuciones?.length > 0 && (
                  <div>
                    <b>Cecos: </b>
                    {rowDataSelection.Distribuciones?.map((e, index) => (
                      <div key={index} style={{ margin: "3.5px 0 3.5px 6px" }}>
                        ({e.Cecos.Codigo}) - {e.Cecos.Cliente} <b>|</b> %
                        {e.Porcentaje}
                      </div>
                    ))}
                  </div>
                )}
                {rowDataSelection.Descripcion ===
                  "Informe y autorización horas extras" &&
                  rowDataSelection.Recursos !== null &&
                  rowDataSelection.Recursos.length > 0 && (
                    <div>
                      {rowDataSelection.Recursos.map(
                        (e, index) =>
                          e.HorasExtras && (
                            <div
                              key={index}
                              style={{ margin: "3px 0 3px 6px" }}
                            >
                              {e.HorasExtras?.map((p, i) => (
                                <div key={i}>
                                  <b>Fecha: </b> {e.Periodo}
                                  <div>
                                    <i>Cantidad de Hs:</i> {p.Cantidad}{" "}
                                    <b>| </b> <i>Tipo Dia:</i> {p.TipoDia}{" "}
                                    <b>| </b> {p.TipoHora}
                                  </div>
                                </div>
                              ))}
                            </div>
                          )
                      )}
                    </div>
                  )}
                {rowDataSelection.ImporteTotal !== 0 && (
                  <div>
                    <b>Importe Total: </b>${" "}
                    {formatNumber(rowDataSelection.ImporteTotal)}
                  </div>
                )}
                {rowDataSelection.FechaDesde !== "" &&
                  (rowDataSelection.FechaHasta !== "" ? (
                    <div>
                      <b>Fecha Desde: </b>
                      {rowDataSelection.FechaDesde}
                      <br />
                      <b>Fecha Hasta: </b>
                      {rowDataSelection.FechaHasta}
                    </div>
                  ) : (
                    <div>
                      <b>Fecha: </b>
                      {rowDataSelection.FechaDesde}
                    </div>
                  ))}
                {rowDataSelection.Cantidad &&
                  rowDataSelection.Cantidad !== "0" && (
                    <div>
                      <b>Cantidad de Dias: </b>
                      {rowDataSelection.Cantidad}
                    </div>
                  )}
              </div>
            )}
            {/* TIPO DE NOVEDAD CLIENTES */}
            {tipoDeNovedad === "Clientes" && (
              <div>
                {/* {rowDataSelection.Tipo !== "HE" ||
                  (rowDataSelection.Tipo !== "NC" && (
                    <>
                      <b>Periodo</b> {rowDataSelection.Periodo} <br />
                    </>
                  ))} */}
                {rowDataSelection.Cliente !== "" && (
                  <>
                    <strong>
                      {rowDataSelection.ClienteNuevo === true
                        ? "Nuevo Cliente: "
                        : "Cliente: "}
                    </strong>
                    {rowDataSelection.Cliente}
                    <br />
                  </>
                )}
                {rowDataSelection.CecosNuevo !== "" && (
                  <>
                    <b>Nuevo centro de costos: </b>{" "}
                    {rowDataSelection.CecosNuevo} <br />
                  </>
                )}
                {rowDataSelection.Periodo !== "" && (
                  <>
                    <strong>Fecha: </strong>
                    {rowDataSelection.Periodo}
                    <br />
                  </>
                )}
                {rowDataSelection.Recursos !== null &&
                  rowDataSelection.Recursos.length > 0 && (
                    <div>
                      {rowDataSelection.Recursos.length > 1 ? (
                        <b>Recursos: </b>
                      ) : (
                        <b>Recurso: </b>
                      )}
                      {rowDataSelection.Recursos.map((e, index) =>
                        e.Porc ? (
                          <div key={index} style={{ margin: "3px 0 3px 6px" }}>
                            <b>{e.Recurso}: </b>
                            {e.Porc?.map((p, i) => (
                              <div key={i}>
                                <i>Periodo: </i>
                                {e.Periodo} <b>| </b>
                                <i>Horas Extras:</i> {p.Cantidad} <b>| </b>{" "}
                                {p.PorcCC}%
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div key={index} style={{ display: "inline" }}>
                            {e.Recurso}
                          </div>
                        )
                      )}
                    </div>
                  )}

                {rowDataSelection.ConceptoDeFacturacion !== "" &&
                  rowDataSelection.ConceptoDeFacturacion !==
                    "Rendición de Gastos" && (
                    <div>
                      <b>Concepto de Facturacion: </b>{" "}
                      {rowDataSelection.ConceptoDeFacturacion}
                    </div>
                  )}
                {rowDataSelection.ImporteTotal > 0 && (
                  <div>
                    <b>Importe: </b>${" "}
                    {formatNumber(rowDataSelection.ImporteTotal)}
                  </div>
                )}
                {rowDataSelection.EnviarA !== "" && (
                  <div>
                    <b>Enviar a: </b> {rowDataSelection.EnviarA}
                  </div>
                )}
                {rowDataSelection.Contacto !== "" && (
                  <div>
                    <b>Contacto: </b> {rowDataSelection.Contacto}
                  </div>
                )}
                {rowDataSelection.Promovido && (
                  <div>
                    <b>PROMOVIDO</b>
                  </div>
                )}
                {rowDataSelection.OrdenDeCompra !== "" && (
                  <div>
                    <b>Orden de Compra: </b>
                    {rowDataSelection.OrdenDeCompra}
                  </div>
                )}
              </div>
            )}
            {/* TIPO DE NOVEDAD PROVEEDORES */}
            {tipoDeNovedad === "Proveedores" && (
              <div>
                <div>
                  <b>Proveedor: </b> {rowDataSelection.Proveedor}
                </div>
                <div>
                  <b>Periodo: </b>
                  {rowDataSelection.Periodo}
                </div>
                <div>
                  <b>Cecos: </b>
                  {rowDataSelection.Distribuciones?.map((e, index) => (
                    <div key={index} style={{ margin: "3.5px 0 3.5px 6px" }}>
                      ({e.Cecos.Codigo}) - {e.Cecos.Proyecto} -{" "}
                      {e.Cecos.Cliente} <b>|</b> %{e.Porcentaje}
                    </div>
                  ))}
                  <b>Importe: </b>${" "}
                  {formatNumber(rowDataSelection.ImporteTotal)}
                </div>

                {rowDataSelection.Freelance ? (
                  <div>
                    {" "}
                    <b>Freelance: </b> Si{" "}
                  </div>
                ) : (
                  <div>
                    {" "}
                    <b>Freelance: </b> No{" "}
                  </div>
                )}
              </div>
            )}
            {/* TIPO DE NOVEDAD PERSONALES */}
            {tipoDeNovedad === "Novedades personales" && (
              <div>
                {/* {rowDataSelection.Prioridad && (
                  <div>
                    <b>Prioridad: </b> {rowDataSelection.Prioridad}
                  </div>
                )}
                {rowDataSelection.Reclamo === true ? (
                  <div><b>Reclamo</b></div>
                ) : (
                  <div><b>Pedido</b></div>
                )} */}
                {rowDataSelection.Descripcion ===
                  "Informe y autorización horas extras" &&
                  rowDataSelection.Recursos !== null &&
                  rowDataSelection.Recursos.length > 0 && (
                    <div>
                      {rowDataSelection.Recursos.map(
                        (e, index) =>
                          e.HorasExtras && (
                            <div key={index} style={{ margin: "3px 0" }}>
                              {e.HorasExtras?.map((p, i) => (
                                <div key={i}>
                                  <b>Fecha: </b> {e.Periodo}
                                  <div>
                                    <i>Cantidad de Hs:</i> {p.Cantidad}{" "}
                                    <b>| </b> <i>Tipo Dia:</i> {p.TipoDia}{" "}
                                    <b>| </b> {p.TipoHora}
                                  </div>
                                </div>
                              ))}
                            </div>
                          )
                      )}
                    </div>
                  )}
                {rowDataSelection.ImporteTotal !== 0 && (
                  <div>
                    <b>Importe Total: </b>${" "}
                    {formatNumber(rowDataSelection.ImporteTotal)}
                  </div>
                )}
                {rowDataSelection.FechaDesde !== "" &&
                  (rowDataSelection.FechaHasta !== "" ? (
                    <div>
                      <b>Fecha Desde: </b>
                      {rowDataSelection.FechaDesde}
                      <br />
                      <b>Fecha Hasta: </b>
                      {rowDataSelection.FechaHasta}
                    </div>
                  ) : (
                    <div>
                      <b>Fecha: </b>
                      {rowDataSelection.FechaDesde}
                    </div>
                  ))}
                {rowDataSelection.Cantidad && (
                  <div>
                    <b>Cantidad de Dias: </b>
                    {rowDataSelection.Cantidad}
                  </div>
                )}
                {rowDataSelection.Aprobador &&
                  rowDataSelection.Descripcion !==
                    "PO - Informe y autorizacion horas extras" && (
                    <div>
                      <b>Aprobador: </b> {rowDataSelection.Aprobador}
                    </div>
                  )}
              </div>
            )}
            {/* TIPO DE BANDEJA DE ENTRADA */}
            {tipoDeNovedad === "Bandeja de Entrada" && (
              <div>
                {rowDataSelection.Descripcion ===
                  "Informe y autorización horas extras" &&
                  rowDataSelection.Recursos !== null &&
                  rowDataSelection.Recursos.length > 0 && (
                    <div>
                      {rowDataSelection.Recursos.map(
                        (e, index) =>
                          e.HorasExtras && (
                            <div
                              key={index}
                              style={{ margin: "3px 0 3px 6px" }}
                            >
                              {e.HorasExtras?.map((p, i) => (
                                <div key={i}>
                                  <b>Fecha: </b> {e.Periodo}
                                  <div>
                                    <i>Cantidad de Hs:</i> {p.Cantidad}{" "}
                                    <b>| </b> <i>Tipo Dia:</i> {p.TipoDia}{" "}
                                    <b>| </b> {p.TipoHora}
                                  </div>
                                </div>
                              ))}
                            </div>
                          )
                      )}
                    </div>
                  )}
                {rowDataSelection.ImporteTotal !== 0 && (
                  <div>
                    <b>Importe Total: </b>${" "}
                    {formatNumber(rowDataSelection.ImporteTotal)}
                  </div>
                )}
                {rowDataSelection.FechaDesde !== "" &&
                  (rowDataSelection.FechaHasta !== "" ? (
                    <div>
                      <b>Fecha Desde: </b>
                      {rowDataSelection.FechaDesde}
                      <br />
                      <b>Fecha Hasta: </b>
                      {rowDataSelection.FechaHasta}
                    </div>
                  ) : (
                    <div>
                      <b>Fecha: </b>
                      {rowDataSelection.FechaDesde}
                    </div>
                  ))}
                {rowDataSelection.Cantidad && (
                  <div>
                    <b>Cantidad de Dias: </b>
                    {rowDataSelection.Cantidad}
                  </div>
                )}
                {rowDataSelection.Aprobador && (
                  <div>
                    <b>Aprobador: </b> {rowDataSelection.Aprobador}
                  </div>
                )}
              </div>
            )}
            <br />
            {rowDataSelection.Comentarios !== "" && (
              <div style={{ width: "90%" }}>
                <b>Comentarios: </b> <p>{rowDataSelection.Comentarios}</p>
              </div>
            )}
            {rowDataSelection.Adjuntos?.length > 0 && (
              <div>
                <b>Adjuntos: </b>
                {rowDataSelection.Adjuntos.map((adj, index) => (
                  <div key={adj}>
                    <LinkAdjuntos
                      id={rowDataSelection.IdSecuencial}
                      name={adj}
                      pos={index}
                      tipo={TIPOS_ADJUNTOS.GENERAL}
                    />
                  </div>
                ))}
              </div>
            )}
            {rowDataSelection.AdjuntosFact?.length > 0 && (
              <div>
                <b>Adjuntos Fact.: </b>
                {rowDataSelection.AdjuntosFact.map((adj, index) => (
                  <div key={adj}>
                    <LinkAdjuntos
                      id={rowDataSelection.IdSecuencial}
                      name={adj}
                      pos={index}
                      tipo={TIPOS_ADJUNTOS.FACTURAS}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </Dialog>
      )}
    </>
  );
};

export default PopUpResumen;
