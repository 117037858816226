import React, { useState, useContext, useRef } from "react";
import { Field, useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { classNames } from "primereact/utils";
import { Calendar } from "primereact/calendar";
import { InputNumber } from "primereact/inputnumber";
import { SelectButton } from "primereact/selectbutton";
import { AuthContext } from "../../contexts/AuthContext";
import { NovedadesContext } from "../../contexts/NovedadesContext";
import AutoCompleteCustom from "../AutoCompleteCustom";
import FileUploadControl from "../FileUploadControl";
import { fechaHora } from "../../helpers";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import FieldRecursosList from "../FieldRecursosList";
import {
  tipoSolicitudes,
  aprobadores,
  GRUPO_SOLICITUDES_TYPES,
} from "./dataTypes";
import { validateTotUploadSize } from "../../helpers/validateTotUploadSize";
import "./formNovedadesPersonales.css";
import FieldHorasExtrasNP from "../FieldHorasExtrasNP";

const FormNovedadesPersonales = ({
  setDisplayPosition,
  displayPosition,
  position,
}) => {
  const { addNovedad } = useContext(NovedadesContext);
  const { username } = useContext(AuthContext);
  const [grupo, setGrupo] = useState(null);
  const [adjuntos, setAdjuntos] = useState([]);
  const [arrayHorasExtras, setArrayHorasExtras] = useState([]);
  // const [fechaFinal, setfechaFinal] = useState(null);
  const [fechasSeleccionadas, setFechasSeleccionadas] = useState([]);
  const fechaActual = new Date();
  const emailSoporte = process.env.REACT_APP_EMAIL_SOPORTE;

  const toast = useRef(null);

  const onHide = (name) => {
    let _fechasSeleccionadas = [...fechasSeleccionadas];
    setFechasSeleccionadas(_fechasSeleccionadas);
    handleClickOnHide(name);
    setDisplayPosition(false);
    if (name === "displayPosition") {
      setGrupo(null);
      formik.resetForm();
      setArrayHorasExtras([]);
      setFechasSeleccionadas([]);
      setAdjuntos([]);
    }
  };

  const handleClickOnHide = (name) => {
    if (name === "displayPosition") {
      return;
    }
    confirmDialog({
      draggable: false,
      message: "¿Desea cerrar el formulario?",
      header: "Confirmación",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      accept: () => onHide("displayPosition"),
      reject: () => setDisplayPosition(true),
      onHide: (e) => {
        if (e === "reject") {
          setDisplayPosition(true);
        } else {
          onHide("displayPosition");
        }
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      solicitud: "",
      aprobador: "",
      adjuntos: [],
      fechaRH: null,
      importeTotal: "",
      comentarios: "",
    },
    validate: (data) => {
      let errors = {};
      if (!data.solicitud) {
        errors.solicitud = "Campo requerido.";
      }
      if (grupo === GRUPO_SOLICITUDES_TYPES.LIC) {
        if (!data.fechaRH) {
          errors.fechaRH = "Campo requerido.";
        }
      }
      if (grupo === GRUPO_SOLICITUDES_TYPES.SUEL) {
        if (!data.importeTotal) {
          errors.importeTotal = "Campo requerido.";
        }
      }
      return errors;
    },
    onSubmit: (data) => {
      let nuevaFecha;
      let fechaFinalValue;
      if (formik.values.fechaRH === null || formik.values.fechaRH === "") {
        nuevaFecha = "";
        fechaFinalValue = "";
      } else if (formik.values.fechaRH.length > 1) {
        nuevaFecha = formik.values.fechaRH?.map((e) =>
          e === null ? "" : fechaHora(e).fecha
        );
        fechaFinalValue = nuevaFecha?.toString();
      } else {
        nuevaFecha = [fechaHora(formik.values.fechaRH).fecha];
        fechaFinalValue = nuevaFecha?.toString();
      }
      if (formik.values.solicitud.type === "LIEN" && adjuntos.length < 1) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Debe adjuntar al menos un archivo",
          life: 5000,
        });
      } else if (
        formik.values.solicitud?.type === "RHHE" &&
        arrayHorasExtras.length < 1
      ) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "No tienes fechas agregadas",
          life: 5000,
        });
      } else {
        setAdjuntos([]);
        addNovedad(
          {
            Tipo: "NP",
            Fecha: fechaHora().fecha,
            Hora: fechaHora().hora,
            Cantidad: contarDias(fechaFinalValue, data),
            Usuario: username,
            Descripcion: formik.values.solicitud.data,
            RecursosStr: JSON.stringify(arrayHorasExtras),
            FechaDesde:
              grupo === GRUPO_SOLICITUDES_TYPES.LIC ? nuevaFecha[0] : [],
            FechaHasta:
              grupo === GRUPO_SOLICITUDES_TYPES.LIC && nuevaFecha.length > 1
                ? nuevaFecha[1]
                : [],
            ImporteTotal: Number(formik.values.importeTotal),
            Comentarios: formik.values.comentarios,
            Departamento: formik.values.solicitud.area.toLowerCase(),
          },
          adjuntos
        );
        formik.resetForm();
        onHide("displayPosition");
      }
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  const contarDias = (_fechaFinal, data) => {
    if (data.solicitud.data === "Informe y autorización horas extras") {
      const lenghtFechasHe = arrayHorasExtras.length;
      return lenghtFechasHe;
    }
    if (data.solicitud.data === "Préstamo de sueldo") {
      return "0";
    }
    if (!_fechaFinal) {
      return "1";
    }
    const nuevaFecha = _fechaFinal.split(",");
    const FechaInicio = nuevaFecha[0];
    const FechaFin = nuevaFecha[1];
    const fechaInicioObjeto = new Date(FechaInicio);
    const fechaFinObjeto = new Date(FechaFin);
    const diferenciaMilisegundos = fechaFinObjeto - fechaInicioObjeto;
    const diferenciaDias = Math.floor(
      diferenciaMilisegundos / (1000 * 60 * 60 * 24)
    );
    const resultadoDias = diferenciaDias + 1;

    if (
      diferenciaDias === 0 ||
      nuevaFecha[1] === "" ||
      nuevaFecha[1] === undefined
    ) {
      return "1";
    } else {
      return resultadoDias.toString();
    }
  };
  return (
    <Dialog
      draggable={false}
      header="Novedades personales"
      visible={displayPosition}
      position={position}
      modal
      style={{
        width: "50vw",
        height: "100vh",
        maxHeight: "100vh",
        margin: "0",
      }}
      onHide={() => onHide("onHide")}
    >
      <ConfirmDialog />
      <Toast ref={toast} />
      <div className="flex justify-content-center form-novedad">
        <form onSubmit={formik.handleSubmit} className="p-fluid">
          <div className="row-container">
            <div className="input-container">
              <span className="p-float-label">
                <AutoCompleteCustom
                  id="solicitud"
                  arrayValues={tipoSolicitudes}
                  field="data"
                  dropdown
                  value={formik.values.solicitud}
                  forceSelection
                  onChange={(e) => {
                    setGrupo(e.target.value?.grupo);
                    formik.handleChange(e);
                  }}
                  className={classNames({
                    "p-invalid": isFormFieldValid("solicitud"),
                  })}
                />
                <label
                  htmlFor="solicitud"
                  className={classNames({
                    "p-error": isFormFieldValid("solicitud"),
                  })}
                >
                  Tipo solicitud*
                </label>
              </span>
              {getFormErrorMessage("solicitud")}
            </div>
          </div>
          {formik.values.solicitud?.area === "SOPORTE" ? (
            <>
              {" "}
              <br />
              <h4>
                Para recibir asistencia y soporte, por favor envía un correo
                electrónico a {emailSoporte}
              </h4>
            </>
          ) : (
            <>
              {grupo === GRUPO_SOLICITUDES_TYPES.LIC && (
                <div className="row-container">
                  <div className="input-container small">
                    {formik.values.solicitud?.type === "LIPB" ||
                    formik.values.solicitud?.type === "LIGR" ? (
                      <span className="p-float-label">
                        <Calendar
                          locale="es"
                          dateFormat="dd/mm/yy"
                          id="fechaRH"
                          showIcon
                          value={formik.values.fechaRH}
                          onChange={formik.handleChange}
                          className={classNames({
                            "p-invalid": isFormFieldValid("fechaRH"),
                          })}
                        />
                        <label
                          htmlFor="fechaRH"
                          className={classNames({
                            "p-error": isFormFieldValid("fechaRH"),
                          })}
                        >
                          Fecha exacta*
                        </label>
                      </span>
                    ) : (
                      <span className="p-float-label">
                        <Calendar
                          locale="es"
                          selectionMode="range"
                          dateFormat="dd/mm/yy"
                          id="fechaRH"
                          showIcon
                          value={formik.values.fechaRH}
                          onChange={formik.handleChange}
                          className={classNames({
                            "p-invalid": isFormFieldValid("fechaRH"),
                          })}
                        />
                        <label
                          htmlFor="fechaRH"
                          className={classNames({
                            "p-error": isFormFieldValid("fechaRH"),
                          })}
                        >
                          Fecha desde - hasta*
                        </label>
                      </span>
                    )}
                    {getFormErrorMessage("fechaRH")}
                  </div>
                </div>
              )}
              {formik.values.solicitud?.type === "RHHE" && (
                <div>
                  <FieldHorasExtrasNP
                    arrayHorasExtras={arrayHorasExtras}
                    setArrayHorasExtras={setArrayHorasExtras}
                    setFechasSeleccionadas={setFechasSeleccionadas}
                    fechasSeleccionadas={fechasSeleccionadas}
                  />
                </div>
              )}
              {grupo === GRUPO_SOLICITUDES_TYPES.SUEL && (
                <div className="row-container">
                  <div className="input-container small">
                    <span className="p-float-label">
                      <InputNumber
                        minFractionDigits={2}
                        maxFractionDigits={3}
                        id="importeTotal"
                        value={formik.values.importeTotal}
                        autoComplete="off"
                        onValueChange={formik.handleChange}
                        className={classNames({
                          "p-invalid": isFormFieldValid("importeTotal"),
                        })}
                      />
                      <label
                        htmlFor="importeTotal"
                        className={classNames({
                          "p-error": isFormFieldValid("importeTotal"),
                        })}
                      >
                        Importe*
                      </label>
                    </span>
                    {getFormErrorMessage("importeTotal")}
                  </div>
                </div>
              )}
              <div className="row-container">
                <div className="input-container medium">
                  <span className="p-float-label">
                    <InputTextarea
                      id="comentarios"
                      maxLength={500}
                      autoResize
                      value={formik.values.comentarios}
                      onChange={formik.handleChange}
                      autoComplete="off"
                    />
                    <label htmlFor="comentarios">Comentarios</label>
                  </span>
                </div>
              </div>
              <FileUploadControl setAdjuntos={setAdjuntos} />
              <div className="final-submit">
                <Button type="submit" label="Guardar" className="mt-2" disabled={!validateTotUploadSize(adjuntos)} />
              </div>
            </>
          )}
        </form>
      </div>
    </Dialog>
  );
};

export default FormNovedadesPersonales;
