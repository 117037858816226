import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { FilterOperator } from "primereact/api";
import { Dropdown } from "primereact/dropdown";
import { Tooltip } from "primereact/tooltip";
import { InputMask } from "primereact/inputmask";
import { Button } from "primereact/button";
import { AuthContext } from "../contexts/AuthContext";
import { NovedadesContext } from "../contexts/NovedadesContext";
import ModalAceptada from "./ModalAceptada";
import ModalRechazada from "./ModalRechazada";
import {
  IDBodyTemplate,
  capitalizarPrimeraLetra,
  fechaHoraPopup,
  formatNumber,
} from "../helpers";
import PopUpResumen from "./PopUpResumen";
import * as XLSX from "xlsx";

import "../styles/tableRecursos.css";

const TableProveedores = forwardRef(
  ({ bandeja, allChecked, novedadesGrupoState }, ref) => {
    const { usuario, username } = useContext(AuthContext);
    const {
      loading,
      getNovedades,
      cambioNovedad,
      getNovedadesAYR,
      getNovedadesPendientes,
    } = useContext(NovedadesContext);

    const [novedades, setNovedades] = useState([]);
    const [novedadesFiltradas, setNovedadesFiltradas] = useState([]);

    const [visibleModalAceptar, setVisibleModalAceptar] = useState(false);
    const [visibleModalRechazar, setVisibleModalRechazar] = useState(false);
    const [novedadSeleccionada, setNovedadSeleccionada] = useState({});
    const [estados] = useState(["Pendiente", "Aceptada", "Rechazada"]);
    const [resumenVisible, setResumenVisible] = useState(false);
    const [rowDataSelection, setRowDataSelection] = useState({});
    const [tipoDeNovedad, setTipoDeNovedad] = useState();
    const dt = useRef(null);
    const [filteredData, setFilteredData] = useState([]);
    // const index = usuario.Email.indexOf("@");
    // const user = usuario.Email.slice(0, index);
    const [filters, setFilters] = useState({
      IdSecuencial: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      Fecha: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      Proveedor: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      Periodo: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      Descripcion: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      ImporteTotal: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      Adjuntos: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      Resumen: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      Usuario: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      Estado: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
    });

    useEffect(() => {
      const handleGetNovedades = async () => {
        const userRol = () => {
          const roles = {
            serviciosSop: "servicios",
          };
          const rol = roles[usuario.Rol] || usuario.Rol;
          return usuario.Rol === "comercialSop"
            ? "validos=true"
            : usuario.Rol === "board"
            ? ""
            : `rol=${rol}`;
        };

        const data = !bandeja
          ? await getNovedades(
              "tipo=PP",
              novedadesGrupoState ? userRol() : "usuario=" + username
            )
          : await getNovedadesPendientes();
        const dataAceptada = await getNovedadesAYR();

        const dataRol = data.filter((e) => e.Tipo === "PP");

        const dataRolAceptada = dataAceptada.filter((e) => e.Tipo === "PP");

        setNovedades(!bandeja ? data : dataRol.concat(dataRolAceptada));
        setNovedadesFiltradas(
          !bandeja ? data.filter((e) => e.Estado === "pendiente") : dataRol
        );
        setFilteredData(data.filter((e) => e.Estado === "pendiente"));
      };
      handleGetNovedades();
    }, [cambioNovedad, novedadesGrupoState]);

    useEffect(() => {
      setFilteredData(dt.current.props.value);
    }, [dt]);

    useEffect(() => {
      setFilteredData(allChecked ? novedades : novedadesFiltradas);
    }, [allChecked]);

    const handleResumenClick = (rowData) => {
      setTipoDeNovedad("Proveedores");
      setResumenVisible(true);
      setRowDataSelection(rowData);
    };

    const limitarCaracteres = (value, maxLength) => {
      if (value.length > maxLength) {
        return value.substring(0, maxLength) + "...";
      }
      return value;
    };

    const resumenBodyTemplate = (rowData) => {
      return (
        <div
          className="campoResumen"
          onClick={() => handleResumenClick(rowData)}
        >
          {novedadesGrupoState && (
            <>
              <strong>Usuario: </strong> {rowData.Usuario} <br />
            </>
          )}
          <strong>
            {" "}
            {rowData.ProvNuevo ? "Nuevo proveedor: " : "Proveedor: "}{" "}
          </strong>
          {rowData.Proveedor}
          <br />
          <strong>Importe: </strong>$ {formatNumber(rowData.ImporteTotal)}
          <br />
          <strong>Cecos: </strong>
          {rowData.Distribuciones?.map((e, index) => {
            if (index >= 0 && index <= 1) {
              return (
                <div key={index}>
                  ({e.Cecos.Codigo}) - {e.Cecos.Cliente} - %{e.Porcentaje}
                  <br />
                </div>
              );
            } else if (index === 2) {
              return (
                <div key={index}>
                  <b>
                    ...<i>Click para ver todos los cecos</i>
                  </b>
                </div>
              );
            }
          })}
          {rowData.Adjuntos?.length > 0 && (
            <>
              <strong>Adjuntos: </strong>
              {rowData.Adjuntos}
              <br />
            </>
          )}
          {rowData.AdjuntosFact?.length > 0 && (
            <>
              <strong>Adjuntos Fact.: </strong>
              {rowData.AdjuntosFact}
              <br />
            </>
          )}
          {rowData.Motivo !== "" && (
            <>
              <strong>Motivo: </strong>
              {rowData.Motivo}
              <br />
            </>
          )}
          {rowData.Comentarios !== "" && (
            <>
              <strong>Comentarios: </strong>{" "}
              {limitarCaracteres(rowData.Comentarios, 30)}
              <br />
            </>
          )}
        </div>
      );
    };

    const estadoBodyTemplate = (rowData) => {
      return (
        <div>
          {bandeja === true ? (
            rowData.Estado === "pendiente" ? (
              <>
                <button
                  onClick={() => {
                    setVisibleModalAceptar(true);
                    setNovedadSeleccionada(rowData);
                  }}
                  className="button-estado-modal green"
                >
                  <i
                    className="custom-target-icon pi pi-fw pi-check-circle"
                    style={{ width: "60px" }}
                    data-pr-tooltip="Aceptar"
                  ></i>
                </button>
                <br />
                <br />
                <button
                  onClick={() => {
                    setVisibleModalRechazar(true);
                    setNovedadSeleccionada(rowData);
                  }}
                  className="button-estado-modal red"
                >
                  <i
                    className="custom-target-icon pi pi-fw pi-times-circle"
                    style={{ width: "60px" }}
                    data-pr-tooltip="Rechazar"
                  ></i>
                </button>
              </>
            ) : (
              capitalizarPrimeraLetra(rowData.Estado)
            )
          ) : (
            capitalizarPrimeraLetra(rowData.Estado)
          )}
        </div>
      );
    };

    const dateFilterTemplate = (options) => {
      return (
        <div>
          <div style={{ marginBottom: "5px", fontSize: "12px" }}>
            Fecha Exacta*
          </div>
          <InputMask
            value={options.value}
            onChange={(e) => options.filterApplyCallback(e.value)}
            mask="9999-99-99"
            // slotChar="yyyy-mm-dd"
            placeholder="Año-Mes-Dia"
          />
          <div style={{ marginBottom: "5px", fontSize: "12px" }}>Año-Mes*</div>
          <InputMask
            value={options.value}
            onChange={(e) => options.filterApplyCallback(e.value)}
            mask="9999-99"
            // slotChar="yyyy-mm-dd"
            placeholder="Año-Mes"
          />
        </div>
      );
    };

    const statusFilterTemplate = (options) => {
      return (
        <Dropdown
          value={options.value}
          options={estados}
          onChange={(e) => options.filterApplyCallback(e.value)}
          placeholder="Seleccione"
          className="p-column-filter"
          // showClear
          style={{ minWidth: "12rem" }}
        />
      );
    };

    const exportCSV = () => {
      const allAccepted = filteredData.every(
        (product) => product.Estado === "aceptada"
      );
      const allRejected = filteredData.every(
        (product) => product.Estado === "rechazada"
      );

      let headers = [
        "Tipo de novedad",
        "ID",
        "Usuario",
        "Fecha de carga",
        "Estado",
        "Proveedor",
        "Periodo",
        "Cecos",
        "Importe",
        "Freelance",
        "Comentarios",
        "Adjuntos",
        "Adjuntos Fact.",
      ];

      if (allAccepted) {
        headers.push("Aprobado por", "Motivo");
      } else if (allRejected) {
        headers.push("Rechazado por", "Motivo");
      }
      const csvContent = [
        headers,
        ...filteredData.map((product) => {
          let row = [
            product.Descripcion || "",
            product.IdSecuencial || "",
            product.Usuario || "",
            product.Fecha || "",
            product.Estado || "",
            product.Proveedor || "",
            product.Periodo || "",
            product.Distribuciones.map(
              (e) =>
                "(" +
                e.Cecos.Codigo +
                ")-" +
                e.Cecos.Proyecto +
                " " +
                e.Porcentaje +
                "%"
            ).join(", ") || "",
            "$" + product.ImporteTotal?.toLocaleString() || "",
            product.Freelance ? "SI" : "NO" || "",
            product.Comentarios || "",
            product.Adjuntos?.join(", ") || "",
            product.AdjuntosFact?.join(", ") || "",
          ];
          if (allAccepted || allRejected) {
            row.push(
              product.Workflow[0].Autorizador +
                "(" +
                product.Workflow[0].Aprobador +
                ")" +
                fechaHoraPopup(product.Workflow[0].Fecha).fechaHora || "",
              product.Motivo || ""
            );
          }
          return row;
        }),
      ];
      const ws = XLSX.utils.aoa_to_sheet(csvContent);
      ws["!cols"] = [
        { wpx: 100 },
        { wpx: 50 },
        { wpx: 120 },
        { wpx: 80 },
        { wpx: 50 },
        { wpx: 150 },
        { wpx: 50 },
        { wpx: 200 },
        { wpx: 80 },
        { wpx: 30 },
        { wpx: 250 },
        { wpx: 200 },
        { wpx: 200 },
        { wpx: 200 },
      ];

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Hoja1");

      // Exportar el archivo Excel
      XLSX.writeFile(wb, "Proveedores.xlsx");
    };

    useImperativeHandle(ref, () => ({
      ejecutarFuncion: exportCSV,
    }));

    return (
      <>
        {/* {!bandeja && (
          <Button
            icon={"pi pi-download"}
            // loading={isFetching}
            tooltip="Descarga novedades"
            style={{ height: "30px", width: "30px" }}
            onClick={exportCSV}
          />
        )} */}

        <ModalAceptada
          visible={visibleModalAceptar}
          setVisible={setVisibleModalAceptar}
          novedadSeleccionada={novedadSeleccionada}
        />
        <ModalRechazada
          visible={visibleModalRechazar}
          setVisible={setVisibleModalRechazar}
          novedadSeleccionada={novedadSeleccionada}
        />
        <Tooltip target=".custom-target-icon" />
        <DataTable
          onValueChange={(data) => setFilteredData(data)}
          ref={dt}
          value={allChecked ? novedades : novedadesFiltradas}
          scrollable
          paginator
          className="p-datatable-customers"
          rows={10}
          dataKey="IdSecuencial"
          filters={filters}
          filterDisplay="menu"
          responsiveLayout="scroll"
          loading={loading}
          emptyMessage="Sin resultados."
          style={{ fontFamily: "var(--main-font)" }}
          sortField="IdSecuencial"
          sortMode="single" //sortMode="multiple"
          sortOrder={-1}
        >
          <Column
            sortable
            field="IdSecuencial"
            header="ID"
            style={{ justifyContent: "center", minWidth: "2rem" }}
            filter
            filterPlaceholder="Buscar por ID"
            showAddButton={false}
            showFilterOperator={false}
            showFilterMatchModes={false}
            body={IDBodyTemplate}
          />
          <Column
            sortable
            field="Fecha"
            header="Fecha"
            style={{ minWidth: "12rem" }}
            filter
            filterElement={dateFilterTemplate}
            filterPlaceholder="Buscar por fecha"
            showAddButton={false}
            showFilterOperator={false}
            showFilterMatchModes={false}
          />
          {usuario.Rol === "admin" && bandeja === true && (
            <Column
              sortable
              field="Usuario"
              header="Usuario"
              style={{ minWidth: "8rem" }}
              filter
              filterPlaceholder="Buscar por Usuario"
              showAddButton={false}
              showFilterOperator={false}
              showFilterMatchModes={false}
            />
          )}
          <Column
            sortable
            field="Proveedor"
            header="Proveedor"
            style={{ minWidth: "8rem" }}
            filter
            filterPlaceholder="Buscar por proveedor"
            showAddButton={false}
            showFilterOperator={false}
            showFilterMatchModes={false}
          />
          <Column
            sortable
            field="Periodo"
            header="Período"
            style={{ minWidth: "12rem" }}
            filter
            filterPlaceholder="Buscar por período"
            showAddButton={false}
            showFilterOperator={false}
            showFilterMatchModes={false}
          />
          <Column
            sortable
            field="Estado"
            header="Estado"
            style={{ minWidth: "12rem" }}
            filter
            filterPlaceholder="Buscar por status"
            showAddButton={false}
            showFilterOperator={false}
            showFilterMatchModes={false}
            body={estadoBodyTemplate}
            filterElement={statusFilterTemplate}
          />
          <Column
            field="Resumen"
            header="Resumen"
            style={{
              justifyContent: "start",
              minWidth: "400px",
              whiteSpace: "pre-line",
              textAlign: "start",
            }}
            body={resumenBodyTemplate}
            filter
            filterPlaceholder="Buscar"
            showAddButton={false}
            showFilterOperator={false}
            showFilterMatchModes={false}
          />
        </DataTable>
        <PopUpResumen
          resumenVisible={resumenVisible}
          setResumenVisible={setResumenVisible}
          rowDataSelection={rowDataSelection}
          tipoDeNovedad={tipoDeNovedad}
          setTipoDeNovedad={setTipoDeNovedad}
        />
      </>
    );
  }
);

export default TableProveedores;
