import { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";

const apiUrl = process.env.REACT_APP_API_URL;
const key = process.env.REACT_APP_TOKEN_KEY;
const endPoint = "/Archivos/Novedad/Adjuntos";

const LinkAdjuntos = ({ id, name, pos, tipo }) => {
  const { getTokenFromSessionStorage } = useContext(AuthContext);

  const obtenerArchivo = async () => {
    try {
      const token = getTokenFromSessionStorage(key);
      const respuesta = await fetch(
        `${apiUrl}${endPoint}/${id}/?tipo=${tipo}&pos=${pos}`,
        {
          method: "GET",
          mode: "cors",
          responseType: "blob",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      if (respuesta.status === 200) {
        const blob = await respuesta.blob();
        return blob;
      } else {
        console.error("Error: ", respuesta.status, respuesta.statusText);
        return null;
      }
    } catch (error) {
      console.error("Error al obtener los archivos", error);
      return null;
    }
  };

  const handleClick = async () => {
    const blob = await obtenerArchivo();
    if (blob) {
      const urlArchivo = URL.createObjectURL(blob);
      const linkDescarga = document.createElement("a");
      linkDescarga.href = urlArchivo;
      linkDescarga.download = name;
      linkDescarga.click();
      URL.revokeObjectURL(urlArchivo);
    }
  };

  const listItemStyle = {
    textDecoration: "underline",
    listStyleType: "none",
    cursor: "pointer",
  };

  return (
    <li style={listItemStyle} onClick={() => handleClick()}>
      {name}
    </li>
  );
};

export default LinkAdjuntos;
