import React, { useState, useContext, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import { useFormik } from "formik";
import { AuthContext } from "../contexts/AuthContext";
import { NovedadesContext } from "../contexts/NovedadesContext";
import AutoCompleteCustom from "./AutoCompleteCustom";
import FileUploadControl from "./FileUploadControl";
import TableCecos from "./TableCecos";
import { fechaHora } from "../helpers";
import { validateTotUploadSize } from "../helpers/validateTotUploadSize";
import "../styles/formFacturacion.css";

const strConceptoIG = "Rendición de gastos";
const strConceptoFS = "Facturación de servicios";
const strConceptoPB = "Pago B-21";

const FormFacturacion = ({
  setDisplayPosition,
  displayPosition,
  position,
  novedadTipo,
}) => {
  const { addNovedad, periodos, getPeriodos, getCecos, cecos, recursosArray } =
    useContext(NovedadesContext);
  const { username } = useContext(AuthContext);
  const [adjuntos, setAdjuntos] = useState([]);
  const [adjuntosFact, setAdjuntosFact] = useState([]);
  const toast = useRef(null);

  useEffect(() => {
    getCecos();
    getPeriodos();
  }, []);

  const onHide = (name) => {
    handleClickOnHide(name);
    setDisplayPosition(false);

    if (name === "displayPosition") {
      formik.resetForm();
      setAdjuntos([]);
      setAdjuntosFact([]);
    }
  };

  const handleClickOnHide = (name) => {
    if (name === "displayPosition") {
      return;
    }
    confirmDialog({
      draggable: false,
      message: "¿Desea cerrar el formulario?",
      header: "Confirmación",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      accept: () => onHide("displayPosition"),
      reject: () => setDisplayPosition(true),
      onHide: (e) => {
        if (e === "reject") {
          setDisplayPosition(true);
        } else {
          onHide("displayPosition");
        }
      },
    });
  };

  const concepto = () => {
    switch (novedadTipo) {
      case "FS":
        return "";
      case "IG":
        return strConceptoIG;
      default:
        return strConceptoPB;
    }
  };

  const descripcion = () => {
    switch (novedadTipo) {
      case "FS":
        return strConceptoFS;
      case "IG":
        return strConceptoIG;
      default:
        return strConceptoPB;
    }
  };

  const formik = useFormik({
    initialValues: {
      periodo: "",
      cliente: "",
      conceptoDeFacturacion: concepto(),
      importe: null,
      recurso: "",
      enviarA: "",
      contacto: "",
      promovido: false,
      ordenDeCompra: "",
      ordenBoolean: true,
      facturaNro: "",
      comentarios: "",
    },
    validate: (data) => {
      let errors = {};
      if (!data.periodo) {
        errors.periodo = "Campo requerido.";
      }
      if (novedadTipo !== "PB" && !data.cliente) {
        errors.cliente = "Campo requerido.";
      }
      if (!data.conceptoDeFacturacion) {
        errors.conceptoDeFacturacion = "Campo requerido.";
      }
      if (!data.importe) {
        errors.importe = "Campo requerido.";
      }
      // if (!data.enviarA || !emailRegex.test(data.enviarA)) {
      //   errors.enviarA = "Debe ingresar un email válido.";
      // }
      if (novedadTipo !== "PB" && !data.enviarA) {
        errors.enviarA = "Debe ingresar un email válido.";
      }
      if (novedadTipo !== "PB" && !data.contacto) {
        errors.contacto = "Campo requerido.";
      }
      if (novedadTipo === "FS" && data.ordenBoolean && !data.ordenDeCompra) {
        errors.ordenDeCompra = "Campo requerido.";
      }
      if (novedadTipo !== "FS" && !data.recurso) {
        errors.recurso = "Campo requerido.";
      }
      if ((novedadTipo === "IG" || novedadTipo === "PB") && !data.comentarios) {
        errors.comentarios = "Campo requerido.";
      }
      return errors;
    },
    onSubmit: (data) => {
      if (novedadTipo === "IG" && adjuntos.length < 1) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Debe adjuntar al menos un archivo",
          life: 5000,
        });
      } else {
        onHide("displayPosition");
        formik.resetForm();
        let array = [];
        formik.values.recurso?.Rcc?.forEach((e) => {
          array.push({
            Porcentaje: e.CcPorc,
            Cecos: {
              Cliente: e.CcNombre,
              Codigo: parseInt(e.CcNum),
            },
          });
        });
        addNovedad(
          {
            Tipo: novedadTipo,
            Fecha: fechaHora().fecha,
            Hora: fechaHora().hora,
            Usuario: username,
            Periodo: formik.values.periodo,
            Cliente:
              novedadTipo !== "PB" ? formik.values.cliente.Descripcion : "",
            ConceptoDeFacturacion: formik.values.conceptoDeFacturacion,
            ImporteTotal: formik.values.importe,
            EnviarA: formik.values.enviarA,
            Contacto: formik.values.contacto,
            Promovido: formik.values.promovido,
            OrdenDeCompra: formik.values.ordenDeCompra,
            Descripcion: descripcion(),
            RecursosStr:
              novedadTipo === "FS"
                ? []
                : JSON.stringify([{ recurso: formik.values.recurso.Resumen }]),
            DistribucionesStr: JSON.stringify(array),
            FacturaNro: formik.values.facturaNro,
            Comentarios: formik.values.comentarios,
          },
          adjuntos,
          adjuntosFact
        );
        setAdjuntos([]);
        setAdjuntosFact([]);
      }
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  return (
    <Dialog
      draggable={false}
      header={descripcion()}
      visible={displayPosition}
      position={position}
      modal
      style={{
        width: "50vw",
        height: "100vh",
        maxHeight: "100vh",
        margin: "0",
      }}
      onHide={() => onHide("onHide")}
    >
      <ConfirmDialog />
      <Toast ref={toast} />
      <div className="flex justify-content-center form-novedad">
        <form onSubmit={formik.handleSubmit} className="p-fluid">
          <div className="row-container">
            <div className="input-container small">
              <span className="p-float-label">
                <Dropdown
                  id="periodo"
                  name="periodo"
                  value={formik.values.periodo}
                  onChange={formik.handleChange}
                  options={periodos}
                  className={classNames({
                    "p-invalid": isFormFieldValid("periodo"),
                  })}
                />
                <label
                  htmlFor="periodo"
                  className={classNames({
                    "p-error": isFormFieldValid("periodo"),
                  })}
                >
                  {novedadTipo === "FS" ? "Periodo*" : "Fecha*"}
                </label>
              </span>
              {getFormErrorMessage("periodo")}
            </div>
            {novedadTipo !== "PB" && (
              <div className="input-container">
                <span className="p-float-label">
                  <AutoCompleteCustom
                    id="cliente"
                    arrayValues={cecos}
                    field="Descripcion"
                    dropdown
                    value={formik.values.cliente}
                    forceSelection
                    onChange={formik.handleChange}
                    className={classNames({
                      "p-invalid": isFormFieldValid("cliente"),
                    })}
                  />
                  <label
                    htmlFor="cliente"
                    className={classNames({
                      "p-error": isFormFieldValid("cliente"),
                    })}
                  >
                    Centro de Costos*
                  </label>
                </span>
                {getFormErrorMessage("cliente")}
              </div>
            )}
          </div>
          {novedadTipo !== "FS" && (
            <div className="row-container">
              <div className="input-container">
                <span className="p-float-label">
                  <AutoCompleteCustom
                    id="recurso"
                    arrayValues={recursosArray}
                    field="Resumen"
                    dropdown
                    value={formik.values.recurso}
                    forceSelection
                    onChange={formik.handleChange}
                    className={classNames({
                      "p-invalid": isFormFieldValid("recurso"),
                    })}
                  />
                  <label
                    htmlFor="recurso"
                    className={classNames({
                      "p-error": isFormFieldValid("recurso"),
                    })}
                  >
                    Recurso*
                  </label>
                </span>
                {getFormErrorMessage("recurso")}
              </div>
            </div>
          )}
          {novedadTipo === "PB" && (
            <>
              <h4 className="form-subtitle">Centro de costos</h4>
              <TableCecos
                novedadTipo={novedadTipo}
                arrayCecos={formik.values.recurso?.Rcc}
              />
            </>
          )}
          <div className="row-container">
            <div className="input-container">
              <span className="p-float-label">
                <InputTextarea
                  id="conceptoDeFacturacion"
                  maxLength="200"
                  autoResize
                  disabled={novedadTipo !== "FS" ? true : false}
                  value={
                    novedadTipo !== "FS"
                      ? concepto()
                      : formik.values.conceptoDeFacturacion
                  }
                  autoComplete="off"
                  onChange={formik.handleChange}
                  className={classNames({
                    "p-invalid": isFormFieldValid("conceptoDeFacturacion"),
                  })}
                />
                <label
                  htmlFor="conceptoDeFacturacion"
                  className={classNames({
                    "p-error": isFormFieldValid("conceptoDeFacturacion"),
                  })}
                >
                  Concepto de Facturación*
                </label>
              </span>
              {getFormErrorMessage("conceptoDeFacturacion")}
            </div>
            <div className="input-container small">
              <span className="p-float-label">
                <InputNumber
                  minFractionDigits={2}
                  maxFractionDigits={3}
                  id="importe"
                  autoComplete="off"
                  value={formik.values.importe}
                  onValueChange={formik.handleChange}
                  className={classNames({
                    "p-invalid": isFormFieldValid("importe"),
                  })}
                />
                <label
                  htmlFor="importe"
                  className={classNames({
                    "p-error": isFormFieldValid("importe"),
                  })}
                >
                  Importe Neto*
                </label>
              </span>
              {getFormErrorMessage("importe")}
            </div>
          </div>
          {novedadTipo !== "PB" && (
            <div className="row-container">
              <div className="input-container">
                <span className="p-float-label">
                  <InputTextarea
                    id="enviarA"
                    value={formik.values.enviarA}
                    autoResize
                    onChange={formik.handleChange}
                    maxLength="200"
                    autoComplete="off"
                    className={classNames({
                      "p-invalid": isFormFieldValid("enviarA"),
                    })}
                  />
                  <label
                    htmlFor="enviarA"
                    className={classNames({
                      "p-error": isFormFieldValid("enviarA"),
                    })}
                  >
                    Enviar a (e-mails)*
                  </label>
                </span>
                {getFormErrorMessage("enviarA")}
              </div>
              <div className="input-container">
                <span className="p-float-label">
                  <InputTextarea
                    id="contacto"
                    value={formik.values.contacto}
                    onChange={formik.handleChange}
                    maxLength="200"
                    autoResize
                    autoComplete="off"
                    className={classNames({
                      "p-invalid": isFormFieldValid("contacto"),
                    })}
                  />
                  <label
                    htmlFor="contacto"
                    className={classNames({
                      "p-error": isFormFieldValid("contacto"),
                    })}
                  >
                    Contactos*
                  </label>
                </span>
                {getFormErrorMessage("contacto")}
              </div>
            </div>
          )}

          {novedadTipo === "FS" && (
            <div className="row-container">
              <div className="input-container switch">
                <span>Promovido</span>
                <InputSwitch
                  id="promovido"
                  checked={formik.values.promovido}
                  onChange={formik.handleChange}
                />
              </div>
            </div>
          )}

          {novedadTipo === "FS" && (
            <div className="row-container">
              <div className="input-container switch">
                <span>Tiene orden de compra?</span>
                <InputSwitch
                  id="ordenBoolean"
                  checked={formik.values.ordenBoolean}
                  onChange={formik.handleChange}
                />
              </div>
              {formik.values.ordenBoolean && (
                <div className="input-container medium">
                  <span className="p-float-label">
                    <InputTextarea
                      id="ordenDeCompra"
                      maxLength="200"
                      autoResize
                      value={formik.values.ordenDeCompra}
                      onChange={formik.handleChange}
                      autoComplete="off"
                      className={classNames({
                        "p-invalid": isFormFieldValid("ordenDeCompra"),
                      })}
                    />
                    <label
                      htmlFor="ordenDeCompra"
                      className={classNames({
                        "p-error": isFormFieldValid("ordenDeCompra"),
                      })}
                    >
                      Orden de compra*
                    </label>
                  </span>
                  {getFormErrorMessage("ordenDeCompra")}
                </div>
              )}
            </div>
          )}

          <div className="row-container">
            <div className="input-container">
              <span className="p-float-label">
                <InputText
                  id="facturaNro"
                  autoComplete="off"
                  maxLength={30}
                  value={formik.values.facturaNro}
                  onChange={formik.handleChange}
                />
                <label htmlFor="facturaNro">Factura Número</label>
              </span>
            </div>
          </div>

          <div className="row-container">
            <div className="input-container">
              <span className="p-float-label">
                <InputTextarea
                  id="comentarios"
                  maxLength={500}
                  autoResize
                  value={formik.values.comentarios}
                  onChange={formik.handleChange}
                  autoComplete="off"
                  className={classNames({
                    "p-invalid": isFormFieldValid("comentarios"),
                  })}
                />
                <label
                  htmlFor="comentarios"
                  className={classNames({
                    "p-error": isFormFieldValid("comentarios"),
                  })}
                >
                  {novedadTipo === "FS" ? "Comentarios" : "Comentarios*"}
                </label>
              </span>
              {getFormErrorMessage("comentarios")}
            </div>
          </div>

          <div className="row-container">
            <div className="input-container">
              <label className="control-title" htmlFor="adjuntosFact">Adjuntar facturas</label>
              <FileUploadControl
                id="adjuntosFact"
                name="adjuntosFact"
                setAdjuntos={setAdjuntosFact}
              />
            </div>
          </div>

          <div className="row-container">
            <div className="input-container">
              <label className="control-title" htmlFor="adjuntos">Adjuntar adicionales</label>
              <FileUploadControl
                id="adjuntos"
                name="adjuntos"
                setAdjuntos={setAdjuntos}
              />
            </div>
          </div>

          <div className="final-submit">
            <Button
              type="submit"
              label="Guardar"
              className="mt-2"
              disabled={!validateTotUploadSize(adjuntos)}
            />
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default FormFacturacion;
