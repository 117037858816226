export const holidaysMock2024 = [
  {
    title: "Año Nuevo",
    start: new Date("2024-01-01 00:00"),
    end: new Date("2024-01-01 23:59"),
  },
  {
    title: "Carnaval",
    start: new Date("2024-02-12 00:00"),
    end: new Date("2024-02-13 23:59"),
  },
  {
    title: "Día Nacional de la Memoria por la Verdad y la Justicia",
    start: new Date("2024-03-24 00:00"),
    end: new Date("2024-03-24 23:59"),
  },
  {
    title: "Jueves/Viernes Santo",
    start: new Date("2024-03-28 00:00"),
    end: new Date("2024-03-29 23:59"),
  },
  {
    title: "Feriado con fines turísticos",
    start: new Date("2024-04-01 00:00"),
    end: new Date("2024-04-01 23:59"),
  },
  {
    title: "Día del Veterano y de los Caídos en la Guerra de Malvinas",
    start: new Date("2024-04-02 00:00"),
    end: new Date("2024-04-02 23:59"),
  },
  {
    title: "Día del Trabajador",
    start: new Date("2024-05-01 00:00"),
    end: new Date("2024-05-01 23:59"),
  },
  {
    title: "Día de la Revolución de Mayo",
    start: new Date("2024-05-25 00:00"),
    end: new Date("2024-05-25 23:59"),
  },
  {
    title: "Paso a la Inmortalidad del Gral. Don Martín Miguel de Güemes",
    start: new Date("2024-06-17 00:00"),
    end: new Date("2024-06-17 23:59"),
  },
  {
    title: "Paso a la Inmortalidad del Gral. Manuel Belgrano",
    start: new Date("2024-06-20 00:00"),
    end: new Date("2024-06-20 23:59"),
  },
  {
    title: "Feriado con fines turísticos",
    start: new Date("2024-06-21 00:00"),
    end: new Date("2024-06-21 23:59"),
  },
  {
    title: "Día de la Independencia",
    start: new Date("2024-07-09 00:00"),
    end: new Date("2024-07-09 23:59"),
  },
  {
    title: "Paso a la Inmortalidad del Gral. José de San Martín",
    start: new Date("2024-08-17 00:00"),
    end: new Date("2024-08-17 23:59"),
  },
  {
    title: "Feriado con fines turísticos",
    start: new Date("2024-10-11 00:00"),
    end: new Date("2024-10-11 23:59"),
  },
  {
    title: "Día del Respeto a la Diversidad Cultural",
    start: new Date("2024-10-12 00:00"),
    end: new Date("2024-10-12 23:59"),
  },
  {
    title: "Dia de la Soberanía Nacional",
    start: new Date("2024-11-18 00:00"),
    end: new Date("2024-11-18 23:59"),
  },
  {
    title: "Inmaculada Concepción de María",
    start: new Date("2024-12-08 00:00"),
    end: new Date("2024-12-08 23:59"),
  },
  {
    title: "Navidad",
    start: new Date("2024-12-25 00:00"),
    end: new Date("2024-12-25 23:59"),
  },
];
