import React, { useRef, useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import { TabPanel, TabView } from "primereact/tabview";
import { Tooltip } from "primereact/tooltip";
import { SpeedDial } from "primereact/speeddial";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { FilterMatchMode } from "primereact/api";
import { FilterOperator } from "primereact/api";
import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
import { InputSwitch } from "primereact/inputswitch";
import { NovedadesContext } from "../contexts/NovedadesContext";
import { AuthContext } from "../contexts/AuthContext";
import FormNovedadesPersonales from "../components/FormNovedadesPersonales";
import PopUpResumen from "../components/PopUpResumen";
import Menu from "../components/Menu";
import logoITP from "../imagenes/logo.png";
import { IDBodyTemplate, capitalizarPrimeraLetra } from "../helpers";
import "../styles/pages/portal-personal.css";
// import FormLicencias from "../components/FormLicencias";
// import FormAnticipo from "../components/FormAnticipo";
// import FormReclamos from "../components/FormReclamos";

const PortalPersonal = () => {
  const history = useNavigate();
  const { loading, getNovedades, cambioNovedad } = useContext(NovedadesContext);
  const { username } = useContext(AuthContext);
  const toast = useRef(null);
  const [tipoDeNovedad, setTipoDeNovedad] = useState();
  const [resumenVisible, setResumenVisible] = useState(false);
  const [rowDataSelection, setRowDataSelection] = useState({});
  const [positionModal, setPositionModal] = useState("right");
  const [estados] = useState(["Pendiente", "Aceptada", "Rechazada"]);
  const [novedades, setNovedades] = useState([]);
  const [novedadesFiltradas, setNovedadesFiltradas] = useState([]);
  const [allChecked, setAllChecked] = useState(false);

  const [displayNovedadesPersonalesModal, setDisplayNovedadesPersonalesModal] =
    useState(false);
  const [filters, setFilters] = useState();

  useEffect(() => {
    initFilters();
  }, []);

  const initFilters = () => {
    setFilters({
      IdSecuencial: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      Cantidad: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      Fecha: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      Proveedor: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      Periodo: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      Descripcion: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      ImporteTotal: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      Adjuntos: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      Resumen: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      Usuario: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      Estado: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
    });
  };

  useEffect(() => {
    const handleGetNovedades = async () => {
      const data = await getNovedades("tipo=NP", "usuario=" + username);
      setNovedades(data);
      setNovedadesFiltradas(data.filter((e) => e.Estado === "pendiente"));
    };
    handleGetNovedades();
  }, [cambioNovedad]);

  const handleResumenClick = (rowData) => {
    setTipoDeNovedad("Novedades personales");
    setResumenVisible(true);
    setRowDataSelection(rowData);
  };

  const handleRedirect = () => {
    history("/500-patagonians");
  };

  const limitarCaracteres = (value, maxLength) => {
    if (value.length > maxLength) {
      return value.substring(0, maxLength) + "...";
    }
    return value;
  };

  const resumenBodyTemplate = (rowData) => {
    return (
      <div className="campoResumen" onClick={() => handleResumenClick(rowData)}>
        <strong>Descripción: </strong>
        {rowData.Descripcion}
        <br />
        {/* {rowData.Reclamo && ( Habilitar opcionalmente este código cuando el endpoint traiga Reclamo*/}
        {/* <>
          <strong>Tipo: </strong>
          {rowData.Reclamo ? "Reclamo" : "Pedido"}
          <br />
        </> */}
        {/* )} */}
        {/* {rowData.Prioridad !== 0 && (
          <>
            <strong>Prioridad: </strong> {rowData.Prioridad}
            <br />
          </>
        )} */}
        {rowData.ImporteTotal !== 0 && (
          <>
            <strong>Importe Total: </strong>$ {rowData.ImporteTotal}
            <br />
          </>
        )}
        {rowData.FechaDesde !== "" &&
          (rowData.FechaHasta !== "" ? (
            <>
              <strong>Fecha desde: </strong>
              {rowData.FechaDesde}
              <br />
              <strong>Fecha hasta: </strong>
              {rowData.FechaHasta}
              <br />
            </>
          ) : (
            <>
              <strong>Fecha: </strong>
              {rowData.FechaDesde}
              <br />
            </>
          ))}
        {rowData.Descripcion === "Informe y autorización horas extras" &&
          rowData.Recursos &&
          rowData.Recursos.length > 0 && (
            <div>
              {rowData.Recursos.map((e, index) => {
                if (index >= 0 && index <= 1 && e.HorasExtras) {
                  return (
                    <div key={index} style={{ margin: "3px 0" }}>
                      {e.HorasExtras?.map((p, i) => (
                        <div key={i}>
                          <b>Fecha: </b> {e.Periodo}
                          <div>
                            <i>Cantidad de Hs:</i> {p.Cantidad} <b>| </b>{" "}
                            <i>Tipo Dia:</i> {p.TipoDia} <b>| </b> {p.TipoHora}
                          </div>
                        </div>
                      ))}
                    </div>
                  );
                } else if (index === 2) {
                  return (
                    <div key={index}>
                      <b>
                        ...<i>Click para ver todas las horas extras</i>
                      </b>
                    </div>
                  );
                }
              })}
            </div>
          )}
        {rowData.Adjuntos?.length > 0 && (
          <>
            <strong>Adjuntos: </strong>
            {rowData.Adjuntos}
            <br />
          </>
        )}
        {rowData.Motivo !== "" && (
          <>
            <strong>Motivo: </strong>
            {rowData.Motivo}
            <br />
          </>
        )}
        {rowData.Comentarios !== "" && (
          <>
            <strong>Comentarios: </strong>{" "}
            {limitarCaracteres(rowData.Comentarios, 30)}
            <br />
          </>
        )}
      </div>
    );
  };

  const estadoBodyTemplate = (rowData) => {
    return (
      <div>
         { capitalizarPrimeraLetra(rowData.Estado)} 
      </div>
    );
  };

  const speedDialItems = [
    {
      label: "Novedades personales",
      icon: "pi pi-ticket",
      command: () => {
        speedDialClick("FormNovedadesPersonales", "right");
      },
    },
  ];

  const speedDialClick = (name) => {
    dialogFuncMap[`${name}`](true);
    if (positionModal) {
      setPositionModal(positionModal);
    }
  };

  const dialogFuncMap = {
    FormNovedadesPersonales: setDisplayNovedadesPersonalesModal,
  };

  const dateFilterTemplate = (options) => {
    return (
      <div>
        <div style={{ marginBottom: "5px", fontSize: "12px" }}>Año-Mes*</div>
        <InputMask
          value={options.value}
          onChange={(e) => options.filterApplyCallback(e.value)}
          mask="9999-99"
          // slotChar="yyyy-mm-dd"
          placeholder="Año-Mes"
        />
      </div>
    );
  };

  const statusFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={estados}
        onChange={(e) => options.filterApplyCallback(e.value)}
        // itemTemplate={statusItemTemplate}
        placeholder="Seleccione"
        className="p-column-filter"
       // showClear
        style={{ minWidth: "12rem" }}
      />
    );
  };

  return (
    <>
      <div className="PatagonianFest-container">
        <div className="container">
          <Toast ref={toast} />
          <Menu />
          <div className="encabezado">
            <h3 className="titulo">Portal de novedades</h3>{" "}
            <img className="imagen_encabezado" src={logoITP} alt="" />
          </div>
          <div className="titulo_novedades">
            <div>
              <h3 className="">Portal del patagonian</h3>
              <div className="speeddial-tooltip-demo">
                <Tooltip
                  target=".speeddial-tooltip-demo .speeddial-right .p-speeddial-action"
                  position="top"
                />
                <SpeedDial
                  className="speed_dial speeddial-right"
                  model={speedDialItems}
                  direction="right"
                />
              </div>
            </div>
            <div className=" switch">
              {/* <Button tooltip="Limpiar Filtros" icon="pi pi-filter-slash" onClick={clearFilter}/> */}
              <InputSwitch
                checked={allChecked}
                onChange={(e) => setAllChecked(e.value)}
              />
              <span style={{ fontSize: "12px" }}>
                Incluir novedades aceptadas y rechazadas
              </span>
            </div>
          </div>
          <div>
            <TabView className="card portal-personal">
              <TabPanel>
                <DataTable
                  value={allChecked ? novedades : novedadesFiltradas}
                  scrollable
                  removableSort
                  paginator
                  className="p-datatable-customers"
                  rows={10}
                  dataKey="IdSecuencial"
                  filters={filters}
                  filterDisplay="menu"
                  responsiveLayout="scroll"
                  loading={loading}
                  emptyMessage="Sin resultados."
                  style={{ fontFamily: "var(--main-font)" }}
                  sortField="IdSecuencial"
                  sortMode="single" //sortMode="multiple"
                  sortOrder={-1}
                 // scrollHeight="300px"
                >
                  <Column
                    sortable
                    removableSort
                    field="IdSecuencial"
                    header="ID"
                    style={{ justifyContent: "center", minWidth: "2rem" }}
                    filter
                    filterPlaceholder="Buscar por ID"
                    showAddButton={false}
                    showFilterOperator={false}
                    showFilterMatchModes={false}
                    body={IDBodyTemplate}
                  />
                  <Column
                    sortable
                    filter
                    field="Cantidad"
                    header="Cant. días"
                    removableSort
                    filterPlaceholder="Buscar por cantidad de días"
                    filterField="Cantidad"
                    // body={contarDias}
                    style={{ justifyContent: "center", minWidth: "2rem" }}
                    showAddButton={false}
                    showFilterOperator={false}
                    showFilterMatchModes={false}
                  />
                  <Column
                    sortable
                    field="Estado"
                    header="Estado"
                    filterField="Estado"
                    removableSort
                    filter
                    style={{ justifyContent: "center", minWidth: "2rem" }}
                    filterPlaceholder="Buscar por status"
                    showAddButton={false}
                    showFilterOperator={false}
                    body={estadoBodyTemplate}
                    showFilterMatchModes={false}
                    filterElement={statusFilterTemplate}
                  />
                  <Column
                    sortable
                    field="Fecha"
                    header="Fecha"
                    removableSort
                    filterField="Fecha"
                    filter
                    filterElement={dateFilterTemplate}
                    style={{ justifyContent: "center", minWidth: "2rem" }}
                    filterPlaceholder="Buscar por Fecha"
                    showAddButton={false}
                    showFilterOperator={false}
                    showFilterMatchModes={false}
                  />
                  <Column
                    field="Resumen"
                    removableSort
                    header="Resumen"
                    style={{
                      justifyContent: "start",
                      minWidth: "400px",
                      whiteSpace: "pre-line",
                      textAlign: "start",
                    }}
                    body={resumenBodyTemplate}
                    filter
                    filterPlaceholder="Buscar"
                    showAddButton={false}
                    showFilterOperator={false}
                    showFilterMatchModes={false}
                  />
                </DataTable>
              </TabPanel>
            </TabView>
            <PopUpResumen
              resumenVisible={resumenVisible}
              setResumenVisible={setResumenVisible}
              rowDataSelection={rowDataSelection}
              tipoDeNovedad={tipoDeNovedad}
              setTipoDeNovedad={setTipoDeNovedad}
            />
          </div>
          <FormNovedadesPersonales
            setDisplayPosition={setDisplayNovedadesPersonalesModal}
            displayPosition={displayNovedadesPersonalesModal}
            position={positionModal}
          />
        </div>
      </div>
    </>
  );
};

export default PortalPersonal;
