import React, { useState, useContext, useRef, useEffect } from "react";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import { AuthContext } from "../contexts/AuthContext";
import { NovedadesContext } from "../contexts/NovedadesContext";
import AutoCompleteCustom from "./AutoCompleteCustom";
import FileUploadControl from "./FileUploadControl";
import { fechaHora } from "../helpers";
import { validateTotUploadSize } from "../helpers/validateTotUploadSize";
import "../styles/formFacturacion.css";

const FormHorasExtras = ({ setDisplayPosition, displayPosition, position }) => {
  const { addNovedad, periodos, getPeriodos, cecos, getCecos } =
    useContext(NovedadesContext);
  const { username } = useContext(AuthContext);
  const [adjuntos, setAdjuntos] = useState([]);
  const [adjuntosFact, setAdjuntosFact] = useState([]);
  const toast = useRef(null);

  useEffect(() => {
    getCecos();
    getPeriodos();
  }, []);

  const onHide = (name) => {
    handleClickOnHide(name);
    setDisplayPosition(false);

    if (name === "displayPosition") {
      formik.resetForm();
      setAdjuntos([]);
      setAdjuntosFact([]);
    }
  };

  const handleClickOnHide = (name) => {
    if (name === "displayPosition") {
      return;
    }
    confirmDialog({
      draggable: false,
      message: "¿Desea cerrar el formulario?",
      header: "Confirmación",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      accept: () => onHide("displayPosition"),
      reject: () => setDisplayPosition(true),
      onHide: (e) => {
        if (e === "reject") {
          setDisplayPosition(true);
        } else {
          onHide("displayPosition");
        }
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      periodo: "",
      cecos: "",
      conceptoDeFacturacion: "",
      facturaNro: "",
      comentarios: "",
    },
    validate: (data) => {
      let errors = {};

      if (!data.periodo) {
        errors.periodo = "Campo requerido.";
      }

      if (!data.cecos) {
        errors.cecos = "Campo Requerido";
      }
      if (!data.conceptoDeFacturacion) {
        errors.conceptoDeFacturacion = "Campo Requerido";
      }
      return errors;
    },

    onSubmit: (data) => {
      if (adjuntos.length <= 0) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Debe adjuntar al menos un archivo ",
          life: 5000,
        });
      } else {
        onHide("displayPosition");
        formik.resetForm();
        setAdjuntos([]);
        setAdjuntosFact([]);
        addNovedad(
          {
            Tipo: "HE",
            Descripcion: "Facturación de horas extras",
            Fecha: fechaHora().fecha,
            Hora: fechaHora().hora,
            Usuario: username,
            Periodo: formik.values.periodo,
            Cliente: formik.values.cecos.Descripcion,
            ConceptoDeFacturacion: formik.values.conceptoDeFacturacion,
            FacturaNro: formik.values.facturaNro,
            Comentarios: formik.values.comentarios,
          },
          adjuntos,
          adjuntosFact
        );
      }
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  const handlePeriodoChange = (e) => {
    formik.handleChange(e);
  };

  return (
    <Dialog
      draggable={false}
      header="Facturación por horas extras"
      visible={displayPosition}
      position={position}
      modal
      style={{
        width: "50vw",
        height: "100vh",
        maxHeight: "100vh",
        margin: "0",
      }}
      onHide={() => onHide("onHide")}
    >
      <ConfirmDialog />
      <Toast ref={toast} />
      <div className="flex justify-content-center form-novedad">
        <form onSubmit={formik.handleSubmit} className="p-fluid">
          <div className="row-container">
            <div className="input-container small">
              <span className="p-float-label">
                <Dropdown
                  id="periodo"
                  name="periodo"
                  value={formik.values.periodo}
                  onChange={handlePeriodoChange}
                  options={periodos}
                  className={classNames({
                    "p-invalid": isFormFieldValid("periodo"),
                  })}
                />
                <label
                  htmlFor="periodo"
                  className={classNames({
                    "p-error": isFormFieldValid("periodo"),
                  })}
                >
                  Período*
                </label>
              </span>
              {getFormErrorMessage("periodo")}
            </div>
            <div className="input-container  medium">
              <span className="p-float-label">
                <AutoCompleteCustom
                  id="cecos"
                  arrayValues={cecos}
                  field="Descripcion"
                  dropdown
                  value={formik.values.cecos}
                  forceSelection
                  onChange={formik.handleChange}
                  className={classNames({
                    "p-invalid": isFormFieldValid("cecos"),
                  })}
                />
                <label
                  htmlFor="cecos"
                  className={classNames({
                    "p-error": isFormFieldValid("cecos"),
                  })}
                >
                  Centro de Costos*
                </label>
                {getFormErrorMessage("cecos")}
              </span>
            </div>
          </div>
          <div className="row-container">
            <div className="input-container">
              <span className="p-float-label">
                <InputTextarea
                  id="conceptoDeFacturacion"
                  maxLength="200"
                  autoResize
                  value={formik.values.conceptoDeFacturacion}
                  onChange={formik.handleChange}
                  autoComplete="off"
                  className={classNames({
                    "p-invalid": isFormFieldValid("conceptoDeFacturacion"),
                  })}
                />
                <label
                  htmlFor="conceptoDeFacturacion"
                  className={classNames({
                    "p-error": isFormFieldValid("conceptoDeFacturacion"),
                  })}
                >
                  Concepto de Facturación*
                </label>
              </span>
              {getFormErrorMessage("conceptoDeFacturacion")}
            </div>
          </div>
          <div className="row-container">
            <div className="input-container">
              <span className="p-float-label">
                <InputText
                  id="facturaNro"
                  autoComplete="off"
                  maxLength={30}
                  value={formik.values.facturaNro}
                  onChange={formik.handleChange}
                />
                <label htmlFor="facturaNro">Factura Número</label>
              </span>
            </div>
          </div>
          <div className="row-container">
            <div className="input-container">
              <span className="p-float-label">
                <InputTextarea
                  id="comentarios"
                  maxLength={500}
                  autoResize
                  value={formik.values.comentarios}
                  autoComplete="off"
                  onChange={formik.handleChange}
                />
                <label htmlFor="comentarios">Comentarios</label>
              </span>
            </div>
          </div>

          <div className="row-container">
            <div className="input-container">
              <label className="control-title" htmlFor="adjuntosFact">Adjuntar facturas</label>
              <FileUploadControl
                id="adjuntosFact"
                name="adjuntosFact"
                setAdjuntos={setAdjuntosFact}
              />
            </div>
          </div>

          <div className="row-container">
            <div className="input-container">
              <label className="control-title" htmlFor="adjuntos">Adjuntar adicionales</label>
              <FileUploadControl
                id="adjuntos"
                name="adjuntos"
                setAdjuntos={setAdjuntos}
              />
            </div>
          </div>

          <div className="final-submit">
            <Button
              type="submit"
              label="Guardar"
              className="mt-2"
              disabled={!validateTotUploadSize(adjuntos)}
            />
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default FormHorasExtras;
