import {
  useContext,
  useEffect,
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";

import { FilterOperator, FilterMatchMode } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { Tooltip } from "primereact/tooltip";
import { AuthContext } from "../contexts/AuthContext";
import { NovedadesContext } from "../contexts/NovedadesContext";
import ModalAceptada from "./ModalAceptada";
import ModalRechazada from "./ModalRechazada";
import {
  IDBodyTemplate,
  capitalizarPrimeraLetra,
  fechaHoraPopup,
  formatNumber,
} from "../helpers";
import { InputMask } from "primereact/inputmask";
import { Button } from "primereact/button";
import PopUpResumen from "./PopUpResumen";
import * as XLSX from "xlsx";

const TablaFacturacion = forwardRef(
  ({ bandeja, allChecked, novedadesGrupoState }, ref) => {
    const { usuario, username } = useContext(AuthContext);
    const {
      loading,
      getNovedades,
      cambioNovedad,
      getNovedadesAYR,
      getNovedadesPendientes,
    } = useContext(NovedadesContext);
    const [novedades, setNovedades] = useState([]);
    const [novedadesFiltradas, setNovedadesFiltradas] = useState([]);
    const [estados] = useState(["Pendiente", "Aceptada", "Rechazada"]);
    const [visibleModalAceptar, setVisibleModalAceptar] = useState(false);
    const [visibleModalRechazar, setVisibleModalRechazar] = useState(false);
    const [novedadSeleccionada, setNovedadSeleccionada] = useState({});
    const [resumenVisible, setResumenVisible] = useState(false);
    const [rowDataSelection, setRowDataSelection] = useState({});
    const [tipoDeNovedad, setTipoDeNovedad] = useState();
    const dt = useRef(null);
    const [filteredData, setFilteredData] = useState([]);

    // const index = usuario.Email.indexOf("@");
    // const user = usuario.Email.slice(0, index);
    const [filters, setFilters] = useState({
      IdSecuencial: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      Fecha: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      Periodo: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      Cliente: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      ConceptoDeFacturacion: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      Resumen: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      Usuario: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      Estado: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
    });

    useEffect(() => {
      const handleGetNovedades = async () => {
        const userRol = () => {
          const roles = {
            serviciosSop: "servicios",
          };
          const rol = roles[usuario.Rol] || usuario.Rol;
          return usuario.Rol === "comercialSop"
            ? "validos=true"
            : usuario.Rol === "board"
            ? ""
            : `rol=${rol}`;
        };

        const data = !bandeja
          ? await getNovedades(
              usuario.Rol === "comercial" ? "tipo=NC" : "tipo=FS|HE|IG|NC",
              novedadesGrupoState ? userRol() : "usuario=" + username
            )
          : await getNovedadesPendientes();
        const dataAceptada = await getNovedadesAYR();
        const dataRol = data.filter((e) => {
          if (usuario.Rol === "comercial") {
            return e.Tipo === "NC";
          } else {
            return ["FS", "HE", "IG", "NC", "PB"].includes(e.Tipo);
          }
        });
        const dataRolAceptada = dataAceptada.filter((e) => {
          if (usuario.Rol === "comercial") {
            return e.Tipo === "NC";
          } else {
            return ["FS", "HE", "IG", "NC"].includes(e.Tipo);
          }
        });
        setNovedades(!bandeja ? data : dataRol.concat(dataRolAceptada));
        setNovedadesFiltradas(
          !bandeja ? data.filter((e) => e.Estado === "pendiente") : dataRol
        );
        setFilteredData(data.filter((e) => e.Estado === "pendiente"));
      };
      handleGetNovedades();
    }, [cambioNovedad, novedadesGrupoState]);

    useEffect(() => {
      setFilteredData(dt.current.props.value);
    }, [dt]);

    useEffect(() => {
      setFilteredData(allChecked ? novedades : novedadesFiltradas);
    }, [allChecked]);

    const handleResumenClick = (rowData) => {
      setTipoDeNovedad("Clientes");
      setResumenVisible(true);
      setRowDataSelection(rowData);
    };

    const limitarCaracteres = (value, maxLength) => {
      if (value.length > maxLength) {
        return value.substring(0, maxLength) + "...";
      }
      return value;
    };

    const resumenBodyTemplate = (rowData) => {
      return (
        <div
          className="campoResumen"
          onClick={() => handleResumenClick(rowData)}
        >
          {novedadesGrupoState && (
            <>
              <strong>Usuario: </strong> {rowData.Usuario} <br />
            </>
          )}
          <strong>Descripción: </strong>
          {rowData.Descripcion}{" "}
          {rowData.Promovido && <strong>PROMOVIDO</strong>}
          <br />
          {rowData.Periodo !== "" && (
            <>
              <strong>Fecha: </strong>
              {rowData.Periodo}
              <br />
            </>
          )}
          {rowData.Cliente !== "" && (
            <>
              <strong>
                {rowData.ClienteNuevo === true ? "Nuevo Cliente:" : "Cliente:"}
              </strong>
              {rowData.Cliente}
              <br />
            </>
          )}
          {rowData.CecosNuevo !== "" && (
            <>
              <strong>Nuevo centro de costros: </strong>
              {rowData.CecosNuevo}
              <br />
            </>
          )}
          {rowData.ImporteTotal > 0 && (
            <>
              <strong>Importe: </strong>$ {formatNumber(rowData.ImporteTotal)}
              <br />
            </>
          )}
          {rowData.OrdenDeCompra !== "" && (
            <>
              <strong>Orden de Compra: </strong>
              {rowData.OrdenDeCompra}
              <br />
            </>
          )}
          {rowData.Recursos !== null && rowData.Recursos.length > 0 && (
            <>
              {rowData.Recursos.length > 1 ? (
                <strong>Recursos: </strong>
              ) : (
                <strong>Recurso: </strong>
              )}

              {rowData.Recursos.map((e, index) => {
                if (index >= 0 && index <= 1) {
                  return (
                    <div key={index}>
                      {e.Recurso} - {e.Periodo}
                      {e.Porc?.map((p, i) => (
                        <div key={i}>
                          HS: {p.Cantidad} - {p.PorcCC}%
                        </div>
                      ))}
                    </div>
                  );
                } else if (index === 2) {
                  return (
                    <div key={index}>
                      <b>
                        ...<i>Click para ver todos los recursos</i>
                      </b>
                    </div>
                  );
                }
              })}
            </>
          )}
          {rowData.Adjuntos?.length > 0 && (
            <>
              <strong>Adjuntos: </strong>
              {rowData.Adjuntos}
              <br />
            </>
          )}
          {rowData.AdjuntosFact?.length > 0 && (
            <>
              <strong>Adjuntos Fact.: </strong>
              {rowData.AdjuntosFact}
              <br />
            </>
          )}
          {rowData.Motivo !== "" && (
            <>
              <strong>Motivo: </strong> {rowData.Motivo}
              <br />
            </>
          )}
          {rowData.Comentarios !== "" && (
            <>
              <strong>Comentarios: </strong>{" "}
              {limitarCaracteres(rowData.Comentarios, 30)}
              <br />
            </>
          )}
        </div>
      );
    };

    const estadoBodyTemplate = (rowData) => {
      return (
        <div>
          {bandeja === true ? (
            rowData.Estado === "pendiente" ? (
              <>
                <button
                  onClick={() => {
                    setVisibleModalAceptar(true);
                    setNovedadSeleccionada(rowData);
                  }}
                  className="button-estado-modal green"
                >
                  <i
                    className="custom-target-icon pi pi-fw pi-check-circle"
                    style={{ width: "60px" }}
                    data-pr-tooltip="Aceptar"
                  ></i>
                </button>
                <br />
                <br />
                <button
                  onClick={() => {
                    setVisibleModalRechazar(true);
                    setNovedadSeleccionada(rowData);
                  }}
                  className="button-estado-modal red"
                >
                  <i
                    className="custom-target-icon pi pi-fw pi-times-circle"
                    style={{ width: "60px" }}
                    data-pr-tooltip="Rechazar"
                  ></i>
                </button>
              </>
            ) : (
              capitalizarPrimeraLetra(rowData.Estado)
            )
          ) : (
            capitalizarPrimeraLetra(rowData.Estado)
          )}
        </div>
      );
    };

    const dateFilterTemplate = (options) => {
      return (
        <div>
          <div style={{ marginBottom: "5px", fontSize: "12px" }}>
            Fecha Exacta*
          </div>
          <InputMask
            value={options.value}
            onChange={(e) => options.filterApplyCallback(e.value)}
            mask="9999-99-99"
            // slotChar="yyyy-mm-dd"
            placeholder="Año-Mes-Dia"
          />
          <div style={{ marginBottom: "5px", fontSize: "12px" }}>Año-Mes*</div>
          <InputMask
            value={options.value}
            onChange={(e) => options.filterApplyCallback(e.value)}
            mask="9999-99"
            // slotChar="yyyy-mm-dd"
            placeholder="Año-Mes"
          />
        </div>
      );
    };

    const statusFilterTemplate = (options) => {
      return (
        <Dropdown
          value={options.value}
          options={estados}
          onChange={(e) => options.filterApplyCallback(e.value)}
          placeholder="Seleccione"
          className="p-column-filter"
          //showClear
          style={{ minWidth: "12rem" }}
        />
      );
    };

    const exportCSV = () => {
      const allAccepted = filteredData.every(
        (product) => product.Estado === "aceptada"
      );
      const allRejected = filteredData.every(
        (product) => product.Estado === "rechazada"
      );

      const includeRecursos = filteredData.some(
        (product) => product.Recursos && product.Recursos.length > 0
      );
      const includeComentarios = filteredData.some(
        (product) => product.Comentarios
      );
      const includeAdjuntos = filteredData.some(
        (product) => product.Adjuntos && product.Adjuntos.length > 0
      );
      const includeAdjuntosFact = filteredData.some(
        (product) => product.AdjuntosFact && product.AdjuntosFact.length > 0
      );
      const includeEnviarA = filteredData.some((product) => product.EnviarA);
      const includeContacto = filteredData.some((product) => product.Contacto);
      const includeOrdenDeCompra = filteredData.some(
        (product) => product.OrdenDeCompra
      );
      const includePromovido = filteredData.some(
        (product) => product.Promovido !== undefined
      );

      let headers = [];
      if (filteredData.every((e) => e.Tipo === "FS")) {
        headers = [
          "Tipo de novedad",
          "ID",
          "Usuario",
          "Fecha de carga",
          "Estado",
          "Cliente",
          "Fecha",
          "Concepto de facturacion",
          "Importe",
          "Enviar a",
          "Contacto",
          "Orden de compra",
          "Promovido",
          "Comentarios",
          "Adjuntos",
          "Adjuntos Fact.",
        ];
      } else if (filteredData.every((e) => e.Tipo === "HE")) {
        headers = [
          "Tipo de novedad",
          "ID",
          "Usuario",
          "Fecha de carga",
          "Estado",
          "Cliente",
          "Fecha",
          "Concepto de facturacion",
          "Comentarios",
          "Adjuntos",
          "Adjuntos Fact.",
        ];
      } else if (filteredData.every((e) => e.Tipo === "IG")) {
        headers = [
          "Tipo de novedad",
          "ID",
          "Usuario",
          "Fecha de carga",
          "Estado",
          "Cliente",
          "Fecha",
          "Recurso",
          "Concepto de facturacion",
          "Importe",
          "Enviar a",
          "Contacto",
          "Comentarios",
          "Adjuntos",
          "Adjuntos Fact.",
        ];
      } else {
        headers = [
          "Tipo de novedad",
          "ID",
          "Usuario",
          "Fecha de carga",
          "Estado",
          "Cliente",
          "Fecha",
          "Concepto de facturacion",
          "Importe",
        ];

        if (includeRecursos) headers.push("Recurso");
        if (includeEnviarA) headers.push("Enviar a");
        if (includeContacto) headers.push("Contacto");
        if (includeOrdenDeCompra) headers.push("Orden de compra");
        if (includePromovido) headers.push("Promovido");
        if (includeComentarios) headers.push("Comentarios");
        if (includeAdjuntos) headers.push("Adjuntos");
        if (includeAdjuntosFact) headers.push("Adjuntos Fact.");
      }

      if (allAccepted) {
        headers.push("Aprobado por", "Motivo");
      } else if (allRejected) {
        headers.push("Rechazado por", "Motivo");
      }
      const csvContent = [
        headers,
        ...filteredData.map((product) => {
          let row = [];

          if (filteredData.every((e) => e.Tipo === "FS")) {
            row = [
              product.Descripcion || "",
              product.IdSecuencial || "",
              product.Usuario || "",
              product.Fecha || "",
              product.Estado || "",
              product.Cliente || "",
              product.Periodo || "",
              product.ConceptoDeFacturacion || "",
              "$" + product.ImporteTotal?.toLocaleString() || "",
              product.EnviarA || "",
              product.Contacto || "",
              product.OrdenDeCompra || "",
              product.Promovido === true ? "SI" : "NO" || "",
              product.Comentarios || "",
              product.Adjuntos?.join(", ") || "",
              product.AdjuntosFact?.join(", ") || "",
            ];
          } else if (filteredData.every((e) => e.Tipo === "HE")) {
            row = [
              product.Descripcion || "",
              product.IdSecuencial || "",
              product.Usuario || "",
              product.Fecha || "",
              product.Estado || "",
              product.Cliente || "",
              product.Periodo || "",
              product.ConceptoDeFacturacion || "",
              product.Comentarios || "",
              product.Adjuntos?.join(", ") || "",
              product.AdjuntosFact?.join(", ") || "",
            ];
          } else if (filteredData.every((e) => e.Tipo === "IG")) {
            row = [
              product.Descripcion || "",
              product.IdSecuencial || "",
              product.Usuario || "",
              product.Fecha || "",
              product.Estado || "",
              product.Cliente || "",
              product.Periodo || "",
              product.Recursos?.map((e) => e.Recurso).join(", ") || "",
              product.ConceptoDeFacturacion || "",
              "$" + product.ImporteTotal?.toLocaleString() || "",
              product.EnviarA || "",
              product.Contacto || "",
              product.Comentarios || "",
              product.Adjuntos?.join(", ") || "",
              product.AdjuntosFact?.join(", ") || "",
            ];
          } else {
            row = [
              product.Descripcion || "",
              product.IdSecuencial || "",
              product.Usuario || "",
              product.Fecha || "",
              product.Estado || "",
              product.Cliente || "",
              product.Periodo || "",
              product.ConceptoDeFacturacion || "",
              "$" + product.ImporteTotal?.toLocaleString() || "",
            ];

            if (includeRecursos)
              row.push(
                product.Recursos?.map((e) => e.Recurso).join(", ") || ""
              );
            if (includeEnviarA) row.push(product.EnviarA || "");
            if (includeContacto) row.push(product.Contacto || "");
            if (includeOrdenDeCompra) row.push(product.OrdenDeCompra || "");
            if (includePromovido)
              row.push(product.Promovido === true ? "SI" : "NO" || "");
            if (includeComentarios) row.push(product.Comentarios || "");
            if (includeAdjuntos) row.push(product.Adjuntos?.join(", ") || "");
            if (includeAdjuntosFact)
              row.push(product.AdjuntosFact?.join(", ") || "");
          }

          if (allAccepted || allRejected) {
            row.push(
              product.Workflow[0].Autorizador +
                "(" +
                product.Workflow[0].Aprobador +
                ")" +
                fechaHoraPopup(product.Workflow[0].Fecha).fechaHora || "",
              product.Motivo || ""
            );
          }
          return row;
        }),
      ];
      const ws = XLSX.utils.aoa_to_sheet(csvContent);
      ws["!cols"] = [
        { wpx: 100 },
        { wpx: 50 },
        { wpx: 120 },
        { wpx: 80 },
        { wpx: 50 },
        { wpx: 150 },
        { wpx: 150 },
        { wpx: 50 },
        { wpx: 80 },
        { wpx: 80 },
        { wpx: 250 },
        { wpx: 200 },
        { wpx: 200 },
        { wpx: 200 },
        { wpx: 200 },
        { wpx: 150 },
        { wpx: 150 },
      ];

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Hoja1");

      // Exportar el archivo Excel
      XLSX.writeFile(wb, "Clientes.xlsx");
    };

    useImperativeHandle(ref, () => ({
      ejecutarFuncion: exportCSV,
    }));

    return (
      <>
        {/* {!bandeja && (
          <Button
            icon={"pi pi-download"}
            // loading={isFetching}
            tooltip="Descarga novedades"
            style={{ height: "30px", width: "30px" }}
            onClick={exportCSV}
          />
        )} */}
        <ModalAceptada
          visible={visibleModalAceptar}
          setVisible={setVisibleModalAceptar}
          novedadSeleccionada={novedadSeleccionada}
        />
        <ModalRechazada
          visible={visibleModalRechazar}
          setVisible={setVisibleModalRechazar}
          novedadSeleccionada={novedadSeleccionada}
        />
        <Tooltip target=".custom-target-icon" />
        <DataTable
          onValueChange={(data) => setFilteredData(data)}
          ref={dt}
          value={allChecked ? novedades : novedadesFiltradas}
          scrollable
          paginator
          className="p-datatable-customers"
          rows={10}
          dataKey="IdSecuencial"
          filters={filters}
          filterDisplay="menu"
          loading={loading}
          responsiveLayout="scroll"
          emptyMessage="Sin resultados."
          style={{ fontFamily: "var(--main-font)" }}
          sortField="IdSecuencial"
          sortMode="single" //sortMode="multiple"
          sortOrder={-1}
        >
          <Column
            sortable
            field="IdSecuencial"
            header="ID"
            style={{ justifyContent: "center", minWidth: "2rem" }}
            filter
            filterPlaceholder="Buscar por ID"
            showAddButton={false}
            showFilterOperator={false}
            showFilterMatchModes={false}
            body={IDBodyTemplate}
          />
          <Column
            sortable
            field="Fecha"
            header="Fecha"
            style={{ justifyContent: "center", minWidth: "8rem" }}
            filter
            filterElement={dateFilterTemplate}
            filterPlaceholder="Buscar por fecha"
            showAddButton={false}
            showFilterOperator={false}
            showFilterMatchModes={false}
          />

          {usuario.Rol === "admin" && bandeja === true && (
            <Column
              sortable
              field="Usuario"
              header="Usuario"
              style={{ minWidth: "8rem" }}
              filter
              filterPlaceholder="Buscar por Usuario"
              showAddButton={false}
              showFilterOperator={false}
              showFilterMatchModes={false}
            />
          )}
          {usuario.Rol === "comercial" && (
            <Column
              sortable
              field="CecosNuevo"
              header="Cecos Nuevo"
              filterField="CecosNuevo"
              style={{ minWidth: "10rem", maxWidth: "13rem" }}
              filter
              filterPlaceholder="Buscar por cecos"
              showAddButton={false}
              showFilterOperator={false}
              showFilterMatchModes={false}
            />
          )}
          {usuario.Rol !== "comercial" && (
            <Column
              sortable
              field="Periodo"
              header="Periodo"
              filterField="Periodo"
              style={{ minWidth: "10rem", maxWidth: "13rem" }}
              filter
              filterPlaceholder="Buscar por período"
              showAddButton={false}
              showFilterOperator={false}
              showFilterMatchModes={false}
            />
          )}
          <Column
            sortable
            field="Cliente"
            header="Cliente"
            filterField="Cliente"
            style={{ minWidth: "10rem", maxWidth: "13rem" }}
            filter
            filterPlaceholder="Buscar por cliente"
            showAddButton={false}
            showFilterOperator={false}
            showFilterMatchModes={false}
          />
          {usuario.Rol !== "comercial" && (
            <Column
              sortable
              field="ConceptoDeFacturacion"
              header="Concepto"
              filterField="ConceptoDeFacturacion"
              style={{ minWidth: "14rem" }}
              filter
              filterPlaceholder="Buscar por concepto"
              showAddButton={false}
              showFilterOperator={false}
              showFilterMatchModes={false}
            />
          )}

          <Column
            sortable
            field="Estado"
            header="Estado"
            style={{ minWidth: "12rem" }}
            filter
            filterPlaceholder="Buscar por status"
            showAddButton={false}
            showFilterOperator={false}
            showFilterMatchModes={false}
            body={estadoBodyTemplate}
            filterElement={statusFilterTemplate}
          />
          <Column
            field="Resumen"
            header="Resumen"
            style={{
              justifyContent: "start",
              minWidth: "300px",
              whiteSpace: "pre-line",
              textAlign: "start",
            }}
            body={resumenBodyTemplate}
            filter
            filterPlaceholder="Buscar"
            showAddButton={false}
            showFilterOperator={false}
            showFilterMatchModes={false}
          />
        </DataTable>
        <PopUpResumen
          resumenVisible={resumenVisible}
          setResumenVisible={setResumenVisible}
          rowDataSelection={rowDataSelection}
          tipoDeNovedad={tipoDeNovedad}
          setTipoDeNovedad={setTipoDeNovedad}
        />
      </>
    );
  }
);

export default TablaFacturacion;
