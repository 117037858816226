import { useState } from "react";
import { FileUpload } from "primereact/fileupload";
import { Message } from "primereact/message";

const tamañoMaxArchivo = 3 * 1000 * 1000; // 3 Mb
const tamañoMaxTotalArchivos = 10 * 1000 * 1000; // 10 Mb, por ejemplo

const FileUploadControl = ({ setAdjuntos }) => {
  const [adjuntosState, setAdjuntosState] = useState([]);
  const [totalSize, setTotalSize] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");

  const chooseOptions = {
    icon: "pi pi-fw pi-paperclip",
    iconOnly: true,
    className: "custom-choose-btn p-button-rounded p-button-outlined",
  };

  const uploadHandler = (e) => {
    const nuevosArchivos = e.files.filter(nuevoArchivo => {
      return !adjuntosState.some(adjunto => adjunto.name === nuevoArchivo.name && adjunto.size === nuevoArchivo.size);
    });

    const nuevoTotal = nuevosArchivos.reduce((acc, file) => acc + file.size, totalSize);

    const actualizadosAdjuntos = [...adjuntosState, ...nuevosArchivos];
    setTotalSize(nuevoTotal);
    setAdjuntosState(actualizadosAdjuntos);
    setAdjuntos(actualizadosAdjuntos); // Actualiza el estado en el componente padre si es necesario
    
    if (nuevoTotal > tamañoMaxTotalArchivos) {
      setErrorMessage(`El tamaño total de los archivos no debe exceder los ${(tamañoMaxTotalArchivos / 1000 / 1000).toFixed(2)} MB.`);
      return;
    }
    setErrorMessage("");
  };

  const onRemove = (e) => {
    const archivoEliminado = e.file;
    const archivosRestantes = adjuntosState.filter(adjunto => adjunto.name !== archivoEliminado.name || adjunto.size !== archivoEliminado.size);
    const nuevoTotal = archivosRestantes.reduce((acc, file) => acc + file.size, 0);

    setAdjuntosState(archivosRestantes);
    setTotalSize(nuevoTotal);
    setAdjuntos(archivosRestantes); // Actualiza el estado en el componente padre si es necesario
    
    if (nuevoTotal < tamañoMaxTotalArchivos) {
      setErrorMessage("");
    }
  };

  return (
    <>
      <div className="row-container" style={{marginTop: "10px"}}>
        <div className="input-container">
          <FileUpload
            style={{
              paddingRight: 15,
            }}
            chooseOptions={chooseOptions}
            auto
            name="fileUploadControl"
            customUpload
            uploadHandler={uploadHandler}
            multiple
            accept="image/*,.pdf,.xls,.xlsx,.doc,.docx"
            maxFileSize={tamañoMaxArchivo}
            emptyTemplate={
              <p className="m-0">{`Arrastre o examine archivos. (tamaño máximo ${
                tamañoMaxArchivo / 1000 / 1000
              } MB)`}</p>
            }
            invalidFileSizeMessageSummary="Error! "
            invalidFileSizeMessageDetail={`El tamaño del archivo a adjuntar supera el máximo permitido.`}
            onRemove={onRemove}
          />
        </div>
      </div>
      <div>
        <p style={{fontSize: "14px"}}>Tamaño total de archivos subidos: {(totalSize / 1000 / 1000).toFixed(2)} MB</p>
        {errorMessage && <Message severity="error" text={errorMessage} />}
      </div>
    </>
  );
};

export default FileUploadControl;
