import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterOperator, FilterMatchMode } from "primereact/api";
import { classNames } from "primereact/utils";
import { useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import { roleOptions } from "../../../types/rolesUsuario";

const TablaUsuariosRoles = () => {
  const [allUsers, setAllUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const { getAllUsers, updateUserRol, usuario, deleteUser, refresh } =
    useContext(AuthContext);
  const filters = {
    Nombre: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    Apellido: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    Email: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    Rol: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    EsAdministrador: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
  };

  const esAdminOptions = [
    { label: "Es Administrador", value: true },
    { label: "No es Administrador", value: false },
  ];
  const getEsAdmin = (option) => {
    switch (option.EsAdministrador) {
      case true:
        return "Es Administrador";

      case false:
        return "No es Administrador";
    }
  };
  const getLabelRol = (rowData) => {
    switch (rowData.Rol) {
      case "admin":
        return "Administración";

      case "servicios":
        return "Servicios";

      case "po":
        return "People Operation";

      case "poFull":
        return "People Operation Full";

      case "ta":
        return "Talent Acquisition";

      case "cultura":
        return "Cultura";

      case "comercial":
        return "Comercialización";

      case "empleado":
        return "Empleado";

      case "marketing":
        return "Marketing";

      case "comunicaciones":
        return "Comunicaciones";

      case "comercialSop":
        return "Comercialización soporte";

      case "serviciosSop":
        return "Servicios soporte";

      case "sustentabilidad":
        return "Sustentabilidad";

      case "board":
        return "Board";

      case "codeki":
        return "Codeki";

      case "timesheetReport":
        return "TimeSheetReport";

      case "infraSop":
        return "Infra Soporte";

      default:
        return null;
    }
  };

  const isAdminBodyTemplate = (rowData) => {
    return (
      <i
        className={classNames("pi", {
          "true-icon pi-check-circle": rowData.EsAdministrador,
          "false-icon pi-times-circle": !rowData.EsAdministrador,
        })}
      ></i>
    );
  };

  const handleGetUsers = async () => {
    const data = await getAllUsers();
    setLoading(false);
    if (data) {
      for (let i = 0; i < data.length; i++) {
        data[i].Nombre = data[i].Apellido + ", " + data[i].Nombre;
        if (data[i].Rol === "") {
          data[i].Rol = "empleado";
        }
      }
      const dataFilter = data.filter((e) => e.Rol !== "empleado");
      setAllUsers(dataFilter);
    }
  };

  useEffect(() => {
    setLoading(true);
    handleGetUsers();
  }, [refresh]);

  const rolEditor = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={roleOptions}
        onChange={(e) => options.editorCallback(e.value)}
        placeholder="Seleccione un Rol"
      />
    );
  };

  const esAdminEditor = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={esAdminOptions}
        onChange={(e) => options.editorCallback(e.value)}
      />
    );
  };

  const onRowEditComplete = (e) => {
    onClickConfirmRol(e);
    let _allUsers = [...allUsers];
    let { newData, index } = e;

    _allUsers[index] = newData;

    setAllUsers(_allUsers);
  };

  const messageClickConfirm = (e) => {
    return (
      <div>
        <div>
          <b>Usuario: </b>
          {e.data.Nombre}
        </div>
        <div>
          <b>Email: </b>
          {e.data.Email}
        </div>
        <br />
        {e.data.Rol === e.newData.Rol &&
        e.data.EsAdministrador === e.newData.EsAdministrador ? (
          <div>
            <Tag severity="info" style={{ fontSize: "12px" }}>
              No realizaste ningun cambio de rol.
            </Tag>
          </div>
        ) : (
          <div>
            {e.newData.Rol === "empleado" && (
              <div>
                <Tag severity="info" style={{ fontSize: "12px" }}>
                  ¡Recuerda! El rol: "empleado" no aparece en la tabla.
                </Tag>
              </div>
            )}
            {e.newData.Email === usuario.Email && (
              <div>
                <Tag severity="info" style={{ fontSize: "12px" }}>
                  Al estar cambiando tu propio rol, la pagina será actualizada.
                </Tag>
              </div>
            )}
            {e.data.Rol !== e.newData.Rol && (
              <Tag severity="warning" style={{ fontSize: "16px" }}>
                Deseas cambiar del Rol: {getLabelRol(e.data)}, al Rol:{" "}
                {getLabelRol(e.newData)}.
              </Tag>
            )}
          </div>
        )}
        <div>
          {e.data.EsAdministrador !== e.newData.EsAdministrador && (
            <Tag severity="warning" style={{ fontSize: "16px" }}>
              Deseas cambiar si el usuario: {getEsAdmin(e.data)}, a:{" "}
              {getEsAdmin(e.newData)}.
            </Tag>
          )}
        </div>
      </div>
    );
  };

  const onClickConfirmRol = (e) => {
    const newRolObj = {
      Email: e.newData.Email,
      Rol: e.newData.Rol === "empleado" ? "" : e.newData.Rol,
      EsAdministrador: e.newData.EsAdministrador,
    };
    confirmDialog({
      message: () => messageClickConfirm(e),
      header: "Cambio de Rol",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      accept: async () => {
        if (
          e.data.Rol === e.newData.Rol &&
          e.data.EsAdministrador === e.newData.EsAdministrador
        ) {
          return;
        }
        await updateUserRol(newRolObj);
        if (e.newData.Rol === "empleado") {
          let _allUsers = [...allUsers];
          const filteredAllUsers = _allUsers.filter(
            (users) => users.Email !== e.newData.Email
          );
          setAllUsers(filteredAllUsers);
        }
        if (e.newData.Email === usuario.Email) {
          window.location.reload();
        }
      },
      onHide: (e) => {
        if (e === "accept") {
          return;
        } else {
          let _allUsers = [...allUsers];
          let { data, index } = e;
          _allUsers[index] = data;
          setAllUsers(_allUsers);
        }
      },
      reject: () => {
        let _allUsers = [...allUsers];
        let { data, index } = e;
        _allUsers[index] = data;
        setAllUsers(_allUsers);
      },
    });
  };

  const esAdminFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={[
          { label: "Es Administrador", value: true },
          { label: "No es Administrador", value: false },
        ]}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Seleccione un Rol"
        className="p-column-filter"
        style={{ minWidth: "12rem" }}
      />
    );
  };
  const rolFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={roleOptions}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Seleccione un Rol"
        className="p-column-filter"
        style={{ minWidth: "12rem" }}
      />
    );
  };

  const onClickDeleteUser = (e) => {
    confirmDialog({
      message: () => messageDelete(e),
      header: "Eliminar Usuario",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      accept: () => {
        deleteUser(e.Email);
        let _allUsers = [...allUsers];
        const filteredAllUsers = _allUsers.filter(
          (users) => users.Email !== e.Email
        );
        setAllUsers(filteredAllUsers);
      },
      onHide: () => console.log(),
      reject: () => console.log(),
    });
  };

  const messageDelete = (e) => {
    return (
      <div>
        {e.Email && (
          <>
            <Tag severity="warning" style={{ fontSize: "16px" }}>
              Deseas Eliminar al Usuario: {e.Email}
            </Tag>
          </>
        )}
      </div>
    );
  };
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <DataTable
        value={allUsers}
        scrollable
        paginator
        editMode="row"
        onRowEditComplete={onRowEditComplete}
        loading={loading}
        className="p-datatable-customers"
        rows={15}
        dataKey="IdSecuencial"
        filters={filters}
        filterDisplay="menu"
        // responsiveLayout="scroll"
        emptyMessage="Sin resultados."
        style={{ fontFamily: "var(--main-font)", width: "90%" }}
      >
        <ConfirmDialog style={{ maxWidth: "0rem" }} />
        <Column
          sortable
          field="Nombre"
          header="Nombre"
          style={{ minWidth: "16rem", maxWidth: "44rem" }}
          filter
          filterField="Nombre"
          filterPlaceholder="Buscar por Nombre"
          showAddButton={false}
          showFilterOperator={false}
          showFilterMatchModes={false}
        />
        <Column
          sortable
          field="Email"
          header="Email"
          style={{ minWidth: "16rem", maxWidth: "44rem" }}
          filter
          filterPlaceholder="Buscar por Email"
          showAddButton={false}
          showFilterOperator={false}
          showFilterMatchModes={false}
        />
        <Column
          sortable
          field="Rol"
          header="Rol"
          body={(rowData) => getLabelRol(rowData)}
          editor={(options) => rolEditor(options)}
          style={{
            justifyContent: "center",
            minWidth: "12rem",
            maxWidth: "22rem",
          }}
          filter
          filterPlaceholder="Buscar por Rol"
          showAddButton={false}
          showFilterOperator={false}
          showFilterMatchModes={false}
          filterElement={rolFilterTemplate}
        />
        <Column
          sortable
          field="EsAdministrador"
          dataType="boolean"
          body={isAdminBodyTemplate}
          editor={(options) => esAdminEditor(options)}
          header="Administrador"
          style={{ minWidth: "7rem", maxWidth: "18rem" }}
          filter
          showAddButton={false}
          showFilterOperator={false}
          showFilterMatchModes={false}
          filterElement={esAdminFilterTemplate}
        />
        {/* <Column
          header="Cambio de Rol"
          style={{ minWidth: "8rem", maxWidth: "10rem" }}
          body={(rowData) => (
            <Button label="Guardar" onClick={() => handleSaveChanges(rowData)} />
          )}
        /> */}
        <Column
          rowEditor
          style={{ minWidth: "6rem", maxWidth: "15rem" }}
          header="Cambio de rol"
          bodyStyle={{ textAlign: "center" }}
        />

        <Column
          style={{ minWidth: "6rem", maxWidth: "15rem" }}
          header="Eliminar usuario"
          bodyStyle={{ textAlign: "center" }}
          body={(rowData) => (
            <Button
              onClick={() => onClickDeleteUser(rowData)}
              style={{
                padding: "4px",
                fontSize: "13px",
                backgroundColor: "red",
              }}
            >
              ELIMINAR
            </Button>
          )}
        />
      </DataTable>
    </div>
  );
};

export default TablaUsuariosRoles;
