import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

const RecursosTable = ({ dataValues }) => {
  // const columnGerente = (rowData) => {
  //   const legajoGerente = parseInt(rowData.Gerente);
  //   const dataGerente = dataValues.filter((x) => x.Legajo === legajoGerente);
  //   return <div>{dataGerente[0]?.datoGerente}</div>;
  // };

  const PorcentajeCecos = (rowData) => {
    const ccNumeroPartes = rowData.ccNumero.split(" - ");
    const ccPorcentajePartes = rowData.ccPorcentaje.split(" - ");
  
    const resultado = ccNumeroPartes
    .map((numero, index) => {
      const porcentaje = ccPorcentajePartes[index] || "";
      if (porcentaje === "100") {
        return numero;
      } else if (porcentaje === ""){
        return null;
      }
      return `${numero} (${porcentaje}%)`;
      
    })
    .filter(Boolean)
    .join(" - ");
  
    return (
      <div>
        <p>{resultado}</p>
      </div>
    );
  };

  return (
    <DataTable
      value={dataValues}
      scrollable
      paginator
      className="p-datatable-customers"
      rows={12}
      dataKey="IdSecuencial"
      filterDisplay="menu"
      responsiveLayout="scroll"
      emptyMessage="Sin resultados."
      style={{ fontFamily: "var(--main-font)" }}
      sortField="IdSecuencial"
      sortMode="single"
      sortOrder={-1}
    >
      <Column
        sortable
        field="Legajo"
        header="Legajo"
        style={{ minWidth: "4rem", maxWidth: "12rem" }}
        filter
        filterPlaceholder="Buscar por legajo"
        showAddButton={false}
        showFilterOperator={false}
        showFilterMatchModes={false}
      />
      <Column
        sortable
        field="Apellido"
        header="Apellido"
        style={{
          minWidth: "8rem",
          maxWidth: "18rem",
          textAlign: "start",
          justifyContent: "start",
        }}
        filter
        filterPlaceholder="Buscar por apellido"
        showAddButton={false}
        showFilterOperator={false}
        showFilterMatchModes={false}
      />
      <Column
        sortable
        field="Nombre"
        header="Nombre"
        style={{
          minWidth: "8rem",
          maxWidth: "16rem",
          textAlign: "start",
          justifyContent: "start",
        }}
        filter
        filterPlaceholder="Buscar por nombre"
        showAddButton={false}
        showFilterOperator={false}
        showFilterMatchModes={false}
      />
      <Column
        sortable
        field="nombreGerente"
        header="Gerente"
        style={{
          minWidth: "12rem",
          maxWidth: "20rem",
          textAlign: "start",
          justifyContent: "start",
        }}
        // body={columnGerente}
        filter
        filterPlaceholder="Buscar por gerente"
        filterMatchMode="contains"
        showAddButton={false}
        showFilterOperator={false}
        showFilterMatchModes={false}
      />
      <Column
        sortable
        field="ccNumero"
        header="Numero cecos"
        style={{
          minWidth: "5rem",
          maxWidth: "14rem",
          textAlign: "start",
          justifyContent: "start",
        }}
        body={PorcentajeCecos}
        filter
        filterPlaceholder="Buscar por centro de costos"
        showAddButton={false}
        showFilterOperator={false}
        showFilterMatchModes={false}
      />
      <Column
        sortable
        field="ccNombre"
        header="Nombre cecos"
        style={{
          minWidth: "12rem",
          textAlign: "start",
          justifyContent: "start",
        }}
        filter
        filterPlaceholder="Buscar por centro de costos"
        showAddButton={false}
        showFilterOperator={false}
        showFilterMatchModes={false}
      />
    </DataTable>
  );
};

export default RecursosTable;
